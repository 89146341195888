import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Button } from 'reactstrap';
import {
  AvForm,
  AvField,
  AvInput,
  AvCheckboxGroup,
  AvCheckbox
} from "availity-reactstrap-validation";
import axios from "axios";
import { InlineFormDisplaySection } from 'slices';
import { getColor, createUrl, checkCorporateEmail, getFormattedDate } from '../utils/universalHelperFunctions';
import '../styles/footer.css';
import '@fortawesome/fontawesome-pro/js/all.js';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.footerData = props.footerData;
    this.isFooterPipe = true;
    this.formAPIUrl = "/v1/Forms/services";
    // If form submit valid entries, then handleValidSubmit function fires
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    //  this.submitFormData = this.submitFormData.bind(this);
    this.createRequestObject = this.createRequestObject.bind(this);
    //  this.handleInputChange = this.handleInputChange.bind(this);

    // If form submit invalid entries, then handleInvalidSubmit function fires
    this.handleInvalidSubmit = this.handleInvalidSubmit.bind(this);
    //this.clearTimeoutOfMessage = this.clearTimeoutOfMessage.bind(this);
    this.validateEmailField = this.validateEmailField.bind(this);
    this.createEmailDataObject = this.createEmailDataObject.bind(this);
    this.state = {
      data: {},
      contactFormFields: {},
      selectedFile: null,
      selectedResumeBase64: null,
      isCorporateEmail: (this.formCallPage === "Job Apply" || this.formCallPage === "Walkin") ? true : false,
      selectedFileName: null,
      thankYouMessage: "",
      hideForm: false,
      isFieldValid: true,
      serverError: "",
      emailValidationError: '',
      formId: '',
      domainName: '',
      showSpinner: false,
      hideSubmit: false,
      formLeftLocation: '57%',
      fromActiveCampaign: false,
      enableSubmitButton: false,
      formDisplaySection2Data: this.props.input,
      pageId: this.props.pageId,
      jobPosition: this.props.jobPosition,
      fieldsNotMandatory: null,
    };
  }


  handleInvalidSubmit(event, errors, values) {
    console.log("++++++ Invalid Submit" + errors, values);
  }

  validateEmailField(event) {
    if (this.state.emailValidationError !== '') {
      this.setState({ emailValidationError: '' });
    }
  }

  createRequestObject(values) {
    const { allSlices, pageId, footerData: { body } } = this.props;
    let emailTemplateData = [];

    if (allSlices && allSlices.length > 0) {

      emailTemplateData = body.filter((slice) =>
        [
          "PrismicResourceBodyEmailTemplateLinks",
          "PrismicPagePageContentEmailTemplateLinks",
          "PrismicHomepagePageContentEmailTemplateLinks",
          "PrismicCareerFooterBodyEmailTemplateLinks",
        ].includes(slice.__typename)
      );
    }

    console.log('footer props: ', this.props);

    let requestObject = new FormData();
    requestObject.append('sourceId', this.state.pageId);


    requestObject.append('categoryName', "JOBS");
    requestObject.append('subCategoryName', "JOBS");


    let formObj = {};

    formObj.event = this.footerData.event.text || "test";
    formObj.eventData = this.footerData.event_data.text || "test";

    for (let key in values) {
      if (["firstName", "lastName", "workEmail", "emailAddress", "companyEmailAddress"].indexOf(key) > -1) {
        if (['companyEmailAddress', 'workEmail', 'emailAddress'].indexOf(key) > -1) {
          requestObject.append('email', values[key]);
        } else {
          requestObject.append(key, values[key]);
        }
      } else {
        if (key === 'checkbox') {
          formObj.signUpForEmails = values.checkbox;
        } else {
          formObj[key] = values[key];
        }
      }
    }

    this.state.selectedFile && requestObject.append('file', this.state.selectedFile);
    formObj.country = this.state.cultureFlag;
    this.state.selectedFileName && (formObj.fileName = this.state.selectedFileName);
    formObj.jobPosition = this.props.pageDisplayName;
    formObj.jobID = this.props.jobID;
    values.jobPosition = this.props.pageDisplayName;
    values.country = formObj.country || 'India';
    values.id = this.props.jobID || 'Walkin';

    if (!requestObject.get('subCategoryName')) {
      requestObject.append('subCategoryName', "FIND-A-POSITION");
    }

    values.pageDisplayName = "Career Footer";

    if (emailTemplateData && emailTemplateData.length > 0) {
      console.log('this.createEmailDataObject(values):', this.createEmailDataObject(values))
      requestObject.append('emailData', JSON.stringify(this.createEmailDataObject(values)));
    }

    requestObject.append('formData', JSON.stringify([formObj]));

    return requestObject;
  }

  createEmailDataObject(values) {
    const { footerData: { body } } = this.props;
    let emailTemplateData = [];

    if (body && body.length > 0) {

      emailTemplateData = body.filter((slice) =>
        [
          "PrismicResourceBodyEmailTemplateLinks",
          "PrismicPagePageContentEmailTemplateLinks",
          "PrismicHomepagePageContentEmailTemplateLinks",
          "PrismicCareerFooterBodyEmailTemplateLinks",
        ].includes(slice.__typename)
      );
    }

    console.log('footer emailTemplateData: ', emailTemplateData);
    let emailData = [];
    let workEmail = values.workEmail || values.companyEmailAddress;

    //let emailTemplate = emailTemplateData[0]?.items;
    // if (emailTemplateData && emailTemplateData.length > 0) {
    //   if (this.formDisplaySection2Data.primary.form_identifier) {
    //     emailTemplateData.forEach(template => {
    //       if (this.formDisplaySection2Data.primary.form_identifier && template.primary.form_identifier_for_teamplate === this.formDisplaySection2Data.primary.form_identifier) {
    //         emailTemplate = template.items;
    //       }
    //     });
    //   } else {
    //     emailTemplate = emailTemplateData[0].items;
    //   }
    // }
    emailTemplateData.forEach(emailTemplate => {


      if (emailTemplate) {
        emailTemplate.items.forEach(element => {
          if (this.formCallPage === "Case Study" && element.user_type === "User" && this.state.domainName !== "gspann.com") {
            return;
          }

          if (element.email_template_link && element.email_template_link.document.length > 0) {
            let emailObj = {};
            let emailTemp = element.email_template_link && element.email_template_link.document[0].data;
            let signature = emailTemp.signature && emailTemp.signature.raw.length > 1 ? emailTemp.signature.html : emailTemp.signature.raw[0].text;
            let content = emailTemp.email_content ? emailTemp.email_content.html ? emailTemp.email_content.html : emailTemp.email_content.raw[0].text : '';
            let salutation = emailTemp.salutation ? emailTemp.salutation.html ? emailTemp.salutation.html : emailTemp.salutation.raw[0].text : '';

            if (values) {
              for (let key in values) {
                let expression = '\{' + key + '\}';
                let regEx = new RegExp(expression, 'gi');
                if (content.match(regEx) != null) {
                  content = content.replace(regEx, values[key]);
                }
                if (salutation.match(regEx) != null) {
                  salutation = salutation.replace(regEx, values[key]);
                }
                if (signature.match(regEx) != null) {
                  signature = signature.replace(regEx, values[key]);
                }
              }
            }

            // if (this.resourceOverviewData) {
            //   for (let element in this.resourceOverviewData.primary) {
            //     let key = this.generateKeyForEmailData(element);
            //     let expression = '\{' + key + '\}';
            //     let regEx = new RegExp(expression, 'gi');
            //     if (content.match(regEx) != null) {
            //       content = content.replace(regEx, this.resourceOverviewData.primary[element].text);
            //     }
            //     if (salutation.match(regEx) != null) {
            //       salutation = salutation.replace(regEx, this.resourceOverviewData.primary[element].text);
            //     }
            //     if (signature.match(regEx) != null) {
            //       signature = signature.replace(regEx, this.resourceOverviewData.primary[element].text);
            //     }
            //   }
            // }

            if (this.pageOverViewData) {
              for (let element in this.pageOverViewData.primary) {
                let key = this.generateKeyForEmailData(element);
                let expression = '\{' + key + '\}';
                let regEx = new RegExp(expression, 'gi');
                if (content.match(regEx) != null) {
                  content = content.replace(regEx, this.pageOverViewData.primary[element].text);
                }
                if (salutation.match(regEx) != null) {
                  salutation = salutation.replace(regEx, this.pageOverViewData.primary[element].text);
                }
                if (signature.match(regEx) != null) {
                  signature = signature.replace(regEx, this.pageOverViewData.primary[element].text);
                }
              }
            }

            if (this.pageHeroData) {
              this.pageHeroData.items.forEach(item => {
                for (let element in item) {
                  let key = this.generateKeyForEmailData(element);
                  let expression = '\{' + key + '\}';
                  let regEx = new RegExp(expression, 'gi');
                  if (content.match(regEx) != null) {
                    content = content.replace(regEx, item[element].text);
                  }
                  if (salutation.match(regEx) != null) {
                    salutation = salutation.replace(regEx, item[element].text);
                  }
                  if (signature.match(regEx) != null) {
                    signature = signature.replace(regEx, item[element].text);
                  }
                }
              });
            }

            if (element.description && element.description.html) {
              let descriptionList = element.description.html.split(new RegExp('<p>\\s*@+\\*+@+\\s*<\/p>', 'g'));
              if (descriptionList) {
                let descriptionObj = {}
                descriptionList.forEach(element => {
                  let key = element.match(new RegExp('<p>.*(::)\s*<\/p>'));
                  if (key) {
                    key = key[0].replace(new RegExp('\s*<\/?[^>]+(>|$)\s*', 'g'), '').replace('::', '').trim();
                    descriptionObj[key] = element.split(new RegExp('<p>.*(::)\s*<\/p>'))[2];
                  } else if (element.match(new RegExp('<p>.*(::).*<\/p>'))) {
                    key = element.match(new RegExp('<p>.*(::).*<\/p>'));
                    key = key[0].replace(new RegExp('\s*<\/?[^>]+(>|$)\s*', 'g'), '').split('::');
                    if (key) {
                      descriptionObj[key[0]] = key[1];
                    }
                  } else {
                    return;
                  }
                });
                if (descriptionObj && Object.entries(descriptionObj).length > 0) {
                  for (let key in descriptionObj) {
                    let expression = '\{' + key + '\}';
                    let regEx = new RegExp(expression, 'gi');
                    if (content.match(regEx) != null) {
                      content = content.replace(regEx, descriptionObj[key]);
                    }
                    if (salutation.match(regEx) != null) {
                      salutation = salutation.replace(regEx, descriptionObj[key]);
                    }
                    if (signature.match(regEx) != null) {
                      signature = signature.replace(regEx, descriptionObj[key]);
                    }
                  }
                }
              }
            }

            if (this.formCallPage === "Events") {
              if (this.resourceOverviewData) {
                let startDate = getFormattedDate(this.resourceOverviewData.primary.event_start_timestamp);
                let endDate = getFormattedDate(this.resourceOverviewData.primary.event_end_timestamp);

                content = content.replace('{eventDate}', `${startDate} - ${endDate}`);
              }
            }

            if (this.formCallPage === "Case Study") {
              let id = `${workEmail}|fileDownload`;

              let encryptedID = window.btoa(id);
              let downloadUrl = this.state.formDisplaySection2Data.primary.file_content.url || this.state.fromActiveCampaign ? `${window.location.href}?id=${encryptedID}` : `${window.location.href}`;

              if (new RegExp('href=\"https:\/\/{url}\"', 'g').exec(content)) {
                content = content.replace(/href="https:\/\/{url}"/g, `href=${downloadUrl}`)
              }

              content = content.replace('{url}', downloadUrl);
            }

            if (emailTemp.subject.text) {
              emailObj.sub = emailTemp.subject.text;
            }

            if (this.state.domainName && this.state.domainName === "gspann.com") {
              let regExForRecipient = new RegExp('\{gspann-.*\}', 'gi');
              if (emailTemp.recipient && emailTemp.recipient.text && emailTemp.recipient.text.match(regExForRecipient)) {
                let matchArray = emailTemp.recipient.text.match(regExForRecipient);
                if (matchArray && matchArray.length > 0) {
                  emailTemp.recipient.text = matchArray.join(',');
                }
              }
              if (emailTemp.cc_recipient && emailTemp.cc_recipient.text && emailTemp.cc_recipient.text.match(regExForRecipient) != null) {
                let matchArray = emailTemp.cc_recipient.text.match(regExForRecipient);
                if (matchArray && matchArray.length > 0) {
                  emailTemp.cc_recipient.text = matchArray.join(',');
                }
              }
              if (emailTemp.bcc_recipient && emailTemp.bcc_recipient.text && emailTemp.bcc_recipient.text.match(regExForRecipient) != null) {
                let matchArray = emailTemp.bcc_recipient.text.match(regExForRecipient);
                if (matchArray && matchArray.length > 0) {
                  emailTemp.bcc_recipient.text = matchArray.join(',');
                }
              }
              if (emailTemp.from_recipient && emailTemp.from_recipient.text && emailTemp.from_recipient.text.match(regExForRecipient) != null) {
                let matchArray = emailTemp.from_recipient.text.match(regExForRecipient);
                if (matchArray && matchArray.length > 0) {
                  emailTemp.from_recipient.text = matchArray.join(',');
                }
              }
            }
            if (emailTemp.body && emailTemp.body.length > 0) {
              emailTemp.body.forEach(element => {
                if (element.slice_type === 'recipient_list' && element.items && element.items.length) {
                  element.items.forEach(element => {
                    let expression = `\{${element.team_name.text}\}`;
                    let regEx = new RegExp(expression, 'gi');

                    if (emailTemp.recipient && emailTemp.recipient.text && emailTemp.recipient.text.match(regEx) != null) {
                      emailTemp.recipient.text = emailTemp.recipient.text.replace(regEx, element.recipients.text);
                    }
                    if (emailTemp.cc_recipient && emailTemp.cc_recipient.text && emailTemp.cc_recipient.text.match(regEx) != null) {
                      emailTemp.cc_recipient.text = emailTemp.cc_recipient.text.replace(regEx, element.recipients.text);
                    }
                    if (emailTemp.bcc_recipient && emailTemp.bcc_recipient.text && emailTemp.bcc_recipient.text.match(regEx) != null) {
                      emailTemp.bcc_recipient.text = emailTemp.bcc_recipient.text.replace(regEx, element.recipients.text);
                    }
                    if (emailTemp.from_recipient && emailTemp.from_recipient.text && emailTemp.from_recipient.text.match(regEx) != null) {
                      emailTemp.from_recipient.text = emailTemp.from_recipient.text.replace(regEx, element.recipients.text);
                    }
                  });
                } else if (element.slice_type === 'default_key_value' && element.items && element.items.length) {
                  element.items.forEach(element => {
                    if (element.key.text) {
                      let expression = '\{' + element.key.text + '\}';
                      let regEx = new RegExp(expression, 'gi');
                      let value = element.default_value.text || element.multiline_default_value.html;
                      if (value) {
                        if (content.match(regEx) != null) {
                          content = content.replace(regEx, value);
                        }
                        if (salutation.match(regEx) != null) {
                          salutation = salutation.replace(regEx, value);
                        }
                        if (signature.match(regEx) != null) {
                          signature = signature.replace(regEx, value);
                        }
                      }

                    }
                  });
                }
              });
            }
            if (process.env.GATSBY_ACTIVE_ENV && process.env.GATSBY_ACTIVE_ENV === "staging") {
              if (element.user_type === "User") {
                emailObj.to = workEmail || values.emailAddress;
              } else {
                if (workEmail && this.state.domainName === "gspann.com") {
                  emailObj.to = workEmail;
                } else if (values.emailAddress && values.emailAddress.split('@')[1] === 'gspann.com') {
                  emailObj.to = values.emailAddress;
                } else {
                  emailObj.to = emailTemp.recipient && emailTemp.recipient.text ? emailTemp.recipient.text : '';
                }
              }
            } else {
              if (element.user_type === "User") {
                if (this.formCallPage === "Case Study" && this.state.domainName === "gspann.com") {
                  emailObj.to = workEmail || values.emailAddress;
                } else {
                  emailObj.to = emailTemp.recipient && emailTemp.recipient.text ? emailTemp.recipient.text : workEmail || values.emailAddress;
                }
              } else {
                emailObj.to = emailTemp.recipient && emailTemp.recipient.text ? emailTemp.recipient.text : '';
              }
            }

            salutation = !salutation ? "Hello Visitor" : salutation;

            if (emailTemp.cc_recipient && emailTemp.cc_recipient.text) {
              emailObj.cc = emailTemp.cc_recipient.text;
            }

            if (emailTemp.bcc_recipient && emailTemp.bcc_recipient.text) {
              emailObj.bcc = emailTemp.bcc_recipient.text;
            }

            if (emailTemp.from_recipient && emailTemp.from_recipient.text) {
              emailObj.from = emailTemp.from_recipient.text;
            }

            emailObj.emailTempStr = salutation.concat(content, signature);

            emailObj.userType = element.user_type;

            emailData[emailData.length] = emailObj;
          }
        });
      }
    })
    return emailData;
  }

  submitFormData(values) {
    if (this.formAPIUrl.indexOf(process.env.GATSBY_API_URL) < 0) {
      this.formAPIUrl = this.formAPIUrl.includes('/', 0) ? `${process.env.GATSBY_API_URL}${this.formAPIUrl}` : `${process.env.GATSBY_API_URL}/${this.formAPIUrl}`;
    }
    let successMsg = this.footerData.success_message.text;


    let failureMsg = this.footerData.failure_message.text;
    let requestObject = this.createRequestObject(values);
    console.log('requestObject:', values);
    let config = {}

    if (requestObject.get('categoryName') === "JOBS") {
      config = {
        headers: {
          'content-type': 'multipart/form-data',
          'Cache-Control': 'no-cache'
        },
      }
    } else {
      config = {
        headers: {
          'Cache-Control': 'no-cache'
        },
      }
    }

    axios.post(this.formAPIUrl, requestObject, config)
      .then(response => {
        // let checkboxList = Array.from(document.querySelectorAll("input[type='checkbox']"));
        // if (checkboxList.length) {
        //   checkboxList.forEach(element => {
        //     element.checked = false;
        //   });
        // }
        let fileList = Array.from(document.querySelectorAll(".custom-file-upload input[type='file']"));
        if (fileList.length) {
          fileList.forEach(element => {
            element.value = '';
          });
        }
        this.formRef.reset();
        this.setState({ showSpinner: false });
        this.setState({ selectedFile: null });
        // if (!(this.formCallPage === "Case Study")) {
        //   this.setState({ hideForm: true });
        // }
        this.setState({ thankYouMessage: successMsg });
      })
      .catch(err => {
        console.log(err.response);
        // let checkboxList = document.querySelectorAll("input[type='checkbox']");
        // if (checkboxList.length) {
        //   checkboxList.forEach(element => {
        //     element.checked = false;
        //   });
        // }
        let fileList = document.querySelectorAll(".custom-file-upload input[type='file']");
        if (fileList.length) {
          fileList.forEach(element => {
            element.value = '';
          });
        }
        this.formRef?.reset();
        this.setState({ selectedFile: null });
        this.setState({ showSpinner: false, hideSubmit: true });
        // if (!(this.formCallPage === "Case Study")) {
        //   this.setState({ hideForm: true });
        // }
        this.setState({
          serverError: failureMsg
        });
        setTimeout(() => {
          this.setState({
            serverError: "",
            selectedFile: null,
            hideSubmit: false,
          });
          this.formRef.reset();
        }, 5000);

      });
  }

  handleValidSubmit(event, values) {
    console.log('form values: ', values);
    Object.entries(values).forEach(([key, value]) => {
      if (!(value)) {
        delete values[key];
      }
    });
    this.setState({ showSpinner: true, hideSubmit: true });
    if (values.workEmail || values.companyEmailAddress) {
      this.submitFormData(values);
    } else {
      this.setState({
        data: values
      });


      values.pageTitle = 'jobs';
      values.PageUrl = window.location.href;
      this.submitFormData(values);
    }
  }

  render() {
    const { allSlices, pageId } = this.props;
    // getting GSPANN footer url from footer data
    const footerLogo = this.footerData.footer_logo.url;
    const address = this.footerData.address.text;
    const addressContactNo = address.split('|');

    console.log('footer props: ', this.props);
    return (
      <Container className="footerStyles" fluid style={this.footerData.footer_background_color ? { backgroundColor: '#313542' } : {}}>
        <div className="container">
          <div className="slice-width-global">
            <Row noGutters>
              <Col md="4">
                <img src={footerLogo} width="130" height="30" alt="GSPANN Logo" />
              </Col>
            </Row>
            <Row noGutters className="mt-4">
              <span className="FooterDescription">{this.footerData.footer_description?.text}</span>
            </Row>
            <Row className="footer-social-icon-container" sm="12" md="6">
              <Col md="5" className='footer-form  no-padding'>
                {
                  !this.state.thankYouMessage ? <AvForm onValidSubmit={this.handleValidSubmit}
                    onInvalidSubmit={this.handleInvalidSubmit}
                    ref={el => this.formRef = el}>
                    <Row noGutters>
                      <Col className={`no-padding`} md={12} sm={12} lg={9}>
                        <AvField
                          type="email"
                          name={"emailAddress"}
                          id="emailAddress"
                          // placeholder={`Email Address ${true ? '*' : ''}`}
                          placeholder={`Email Address`}
                          className={`all-form-input errorClass footer-form-email-field`}
                          validate={{
                            required: { value: true, errorMessage: `Field can’t be left blank. Please enter an email.` },
                            email: { value: true, errorMessage: `This is not a valid email format. Please enter a valid email.` },
                          }}
                        />
                      </Col>
                      <Col md="3" className='button footer-sbt-btn'>
                        <Button
                          type='submit'
                          color="primary"
                          size='large'
                          style={{
                            borderWidth: '0px',
                            borderRadius: '0px',
                            backgroundColor: '#F7A900',
                            color: 'white',
                            display: "flex",
                            verticalAlign: 'middle',
                            alignItems: 'center',
                            flexShrink: 0,
                          }}
                          className=""
                        >Subscribe</Button>
                      </Col>
                    </Row>
                  </AvForm> : <span className='message'>{this.state.thankYouMessage}</span>
                }

                {/* <InlineFormDisplaySection
                key={s.id}
                input={s}
                pagesLinksData={this.linksData}
                pageId={pageId}
                emailTemplateData={emailTemplateData}
                pageOverViewData={this.pageOverViewData}
                pageHeaderBodyData={this.pageHeaderBodyData}
                pageHeroData={this.pageHeroData}
                resourceType={resourceType}
                pageDisplayName={this.props.pageDisplayName}
              /> */}
              </Col>
              <Col md="3">
                <Row noGutters className="socialIconRow">
                  {this.footerData.body[2].items && this.footerData.body[2].items.length > 0 && this.footerData.body[2].items.map((item, key) => (
                    <Col sm="2" md="3" xs="2" className="socialIconCol" key={key} style={{ padding: "0px" }}>
                      <a href={item.social_icon_link?.url} target="_blank" hrefLang="en">
                        <i className={`socialIcons ${getColor(item.social_icon)}`} style={item.social_icon_font_color ? { color: getColor(item.social_icon_font_color) } : {}}></i>
                      </a>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
            <Row noGutters>
              <Col md="12">
                {/* <Col md="7"></Col> */}
                <Col md="5" className='footermenu_1'>
                  <Row noGutters className="footerMenu">
                    {this.footerData.body[1].items.map((item, key) => (
                      <div className="m-0" key={item.footer_menu_text.text}>
                        <a
                          className="footer-items-content1"
                          href={item.footer_menu_link?.url}
                          style={item.footer_menu_font_color ? { color: getColor(item.footer_menu_font_color) } : {}}
                          hrefLang="en"
                        >
                          {item.footer_menu_text.text}
                        </a>
                      </div>
                    ))}
                  </Row>

                </Col>
              </Col>
            </Row>

            {/* Copyright content Row */}

            <Row noGutters className="CopyrightRow mt-3">
              <Col xs="12" sm="auto" className="footerTrademarkContent copyright">
                <span className="footerTrademarkContent" style={this.footerData.footer_font_color ? { color: getColor(this.footerData.footer_font_color) } : {}}>
                  {this.footerData.copyright_text.text}
                </span>
              </Col>
              <Col xs="12" sm="auto" className="footerTrademarkContent">
                {
                  this.footerData.body[0].items.map((item, index) => (
                    <React.Fragment key={item.policy_name.text}>
                      <span className={`partition ${!index ? 'd-none' : ''} d-sm-inline px-2`} style={this.footerData.footer_font_color ? { color: getColor(this.footerData.footer_font_color) } : {}}>

                      </span>
                      <a
                        className="footerTrademarkContent"
                        href={item.policy_link?.url ? createUrl(item.policy_link?.url, this.props.pagesLinksData) : ''}
                        style={item.policy_font_color ? { color: getColor(item.policy_font_color) } : {}}
                        hrefLang="en"
                      >
                        {item.policy_name.text}
                      </a>
                    </React.Fragment>
                  ))
                }
              </Col>
              <Col xs="12" sm="auto" className="footerTrademarkContent copyright-mobile">
                <span className="footerTrademarkContent" style={this.footerData.footer_font_color ? { color: getColor(this.footerData.footer_font_color) } : {}}>
                  {this.footerData.copyright_text.text}
                </span>
              </Col>
            </Row>
          </div>
        </div>
      </Container >
    );
  }
}

export default Footer;

Footer.propTypes = {
  footerData: PropTypes.object.isRequired,
  pagesLinksData: PropTypes.array
};
