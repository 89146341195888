import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/client-slideshow.css";

function ContentSlideshow( props) {
    const settings = {
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        adaptiveHeight: false,
        autoplay: true,
        autoplaySpeed: 8000,
        dots: false,
        arrows: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    dots: true,
                    infinite: true,
                    autoplay: true,
                    arrows: false,
                    speed: 500
                }
            },
        ]
    };

    return (
        <Slider {...settings} className="content-slideshow">
            {props.slides}
        </Slider>
    )
}

export default ContentSlideshow;