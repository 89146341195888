import React, { Component } from "react";
import PropTypes from 'prop-types';
import "../styles/latest-job-openings.css";
import Slider from "react-slick";

class LatestJobOpenings extends Component{
    constructor(props) {
        super(props);
        this.latestJobData = props.input;
        this.linksData = props.pagesLinksData; 
        this.allJobs = props.allJobs;
        this.pageOverViewData = props.pageOverViewData;  
          }

          generateJobList(jobList) {
            let jobDataList = [];
            jobList.forEach((item, i) => {
              let itemNode = item.node ? item.node : item.document[0];
              if(itemNode.data.include_job_in_list !== false) {
                let pagePath = itemNode.data.page_path.text;
                const itemBody = itemNode.data.body;
                const jobObj = {};
                const indexForJobTag = obj =>
                  obj.__typename === "PrismicJobDescriptionPageBodyJobTags";
                const indexForJobDescription = obj =>
                  obj.__typename === "PrismicJobDescriptionPageBodyJobDescription";
                const indexForJobKeyDetails = obj =>
                  obj.__typename === "PrismicJobDescriptionPageBodyKeyDetails";
                const indexForPrimarySkills = obj =>
                  obj.__typename === "PrismicJobDescriptionPageBodyPrimarySkills";
                const jobTagIndex = itemBody.findIndex(indexForJobTag);
                const jobDescriptionIndex = itemBody.findIndex(indexForJobDescription);
                const jobKeyDetsilsIndex = itemBody.findIndex(indexForJobKeyDetails);
                const jobPrimarySkillsIndex = itemBody.findIndex(indexForPrimarySkills);
                jobObj.pagePath = pagePath;
                jobObj.displayName = itemNode.data.page_display_name.text;
                jobObj.href = `${itemNode.data.page_path.text}/${itemNode.data.page_display_name.text.split(" ").join("-")}`;
                if(jobDescriptionIndex > -1) {
                  jobObj.title = itemBody[jobDescriptionIndex].primary.job_title;
                  jobObj.description = itemBody[jobDescriptionIndex].primary.description;
                }
                if(jobPrimarySkillsIndex > -1) {
                  let primary_skills = itemBody[jobPrimarySkillsIndex].items.map(item => item.technology);
                  primary_skills = primary_skills.filter(function (el) {
                    return el !== null || el !== "" || el !== 'undefined' || el !== " ";
                  });
                  primary_skills = primary_skills.join(',');
                  jobObj.primary_skills = primary_skills;
                }
                if(jobKeyDetsilsIndex > -1) {
                  jobObj.job_location =  itemBody[jobKeyDetsilsIndex].primary.job_location;
                  jobObj.experience_required = itemBody[jobKeyDetsilsIndex].primary.experience_required;
                  jobObj.job_published_date = itemBody[jobKeyDetsilsIndex].primary.job_published_date;
                  jobObj.job_role_type = itemBody[jobKeyDetsilsIndex].primary.job_role_type;
                  jobObj.job_experience_field_name =  itemBody[jobKeyDetsilsIndex].primary.job_experience_field_name.text;
                  jobObj.location_field_name = itemBody[jobKeyDetsilsIndex].primary.location_field_name.text;
                  jobObj.job_published_date_field_name = itemBody[jobKeyDetsilsIndex].primary.job_published_date_field_name.text;
                  jobObj.role_type_field_name = itemBody[jobKeyDetsilsIndex].primary.role_type_field_name.text;
                }
                jobObj.id = itemNode.id;
                
              
                jobDataList.push(jobObj);
              }
            });
            return jobDataList
          }

          render(){
            const settings = {
              lazyLoad: true,
              className: "center",
              centerPadding: "60px",
              infinite: true,
              speed: 1000,
              slidesToShow: 4,
              slidesToScroll: 1,
              initialSlide: 0,
              adaptiveHeight: false,
              autoplay: false,
              autoplaySpeed: 4000,
              dots: false,
              arrows: true,
              responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 2,
                    dots: true,
                    arrows: false,
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    infinite: true,
                    speed: 1000,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    adaptiveHeight: false,
                    autoplay: true,
                    autoplaySpeed: 4000,
                    dots: true,
                    arrows: false,
                  }
                },
              ]
            };
        
let data = this.generateJobList(this.allJobs);

let carouselCardElements = [];
if(data.length>0){
  data.forEach(element=>{
    if(element.experience_required.text.indexOf('-')>-1){
      let yearsOfexperience = element.experience_required.text.split('-')[0];
      if(parseInt(yearsOfexperience)<2){
        let descriptionElement =(
          <div className="rectangle "> 
          <div className="location">{element.job_location.text}</div>
          <p className="ui-developers-and-le">{element.displayName}</p>
           <a className="apply-now"  style = {{textDecoration:'none'}}  hrefLang="en">
              Apply Now
         </a>
         </div>
        )
        carouselCardElements.push(descriptionElement)
      }
    }
  })
}

            let pageHeader = this.latestJobData.primary.title.text;
            let description = this.latestJobData.primary.description.text;
  
            return (
                <div >
                <div  className="container heading-section-container" id={ pageHeader}>
 <div >
  <h1 className="page-heading">{pageHeader}</h1>
  </div>
  <div className="header-openings-description" dangerouslySetInnerHTML={{
                  __html:description
                    
                }}/>


    



<div className="carousel-div">
                   
                    <Slider {...settings}>
            {carouselCardElements}
          </Slider>
                  </div>
                </div>
                </div>
            )

          }
}

export default LatestJobOpenings;

LatestJobOpenings.propTypes = {
    input: PropTypes.object,
    pagesLinksData: PropTypes.array,
  };