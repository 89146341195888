import React from "react"
import SliceContainer from "../components/SliceContainer";
import { Card } from "./Card";
import "../styles/jobsCard.css";

const JobsCard = (props) => {

    const { items } = props.input;
    const cards = items.map((item, index) => {
        //return <Card key ={`card${index}`} {...item}></Card>;
        return <div className="col-12 col-md-6 pt-3">
            <div className="row noGutter" style={{ margin: "0px" }}>
                <a href={item.page_url?.url} style={{width: '100%'}}>
                    <div className="col-12 jobCard" style={{ backgroundImage: `url(${item.background_image?.url})`, backgroundSize: 'cover' }}>

                        <div className="text-wrapper">
                            <span className='heading'>{item.heading?.text}</span>
                            {/* <a href={`/${page_path?.text}/${page_url?.text ? page_url.text : resource_display_name?.text.split(" ").join("-").toLowerCase()}`}>
                            <span className='read-now'>
                                Read Now
                            </span>
                        </a> */}
                        </div>

                    </div>
                </a>
            </div>
        </div>
    });

    return <SliceContainer  {...props.input}>
        {cards}
    </SliceContainer>
}

export default JobsCard;