import React from "react"
import SliceContainer from "../components/SliceContainer";
import ImageCollage from "../components/ImageCollage";
import { createUrl } from "../utils/universalHelperFunctions";

const CareerDescription = (props) => {

    const data = props.input?.primary;
    const items = props.input?.items;
    // const images = [];
    // items.forEach(item => {
    //     images.push(item.repeatable_image?.url);
    // });
   // console.log("images", images);
    return <SliceContainer {...props.input}>
        {
            //data.display_image_collage ? <ImageCollage images ={images}/> :
            items.map((item, index) => {
                return (item.repetable_text.text) && <div key={index} className={`${item.columns}  align-items-center justify-content-center mb-4`}>
                    {
                        item.repetable_text.text
                    }
                    {/* {
                        item .repeatable_image_position === "Bottom" && item.repeatable_image?.url && <img src={item.repeatable_image?.url}></img>
                    } */}
                </div>
            })
        }
    </SliceContainer>
};

export default CareerDescription;