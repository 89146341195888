import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Collapse
} from "reactstrap";
import PropTypes from "prop-types";
import "./../styles/job-description.css";

class JobDescription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: typeof window !== 'undefined' && window.innerWidth > 768 ? this.props.isOpen: true,
    };
    this.toggle = this.toggle.bind(this);
    this.onResize = this.onResize.bind(this);
    this.icon = React.createRef()
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      this.onResize();
      window.addEventListener('resize', (e) => this.onResize());
    }
  }

  componentDidUpdate() {
    const icon = document.getElementById(`${this.props.fieldLabel.split(' ').join('-')}-icon`);
    console.log('icon:',icon.classList);
    icon.classList.remove('fa-chevron-up');
    icon.classList.remove('fa-chevron-down')
    if(this.state.collapse) {
      icon.classList.add('fa-chevron-up');
    } else {
      icon.classList.add('fa-chevron-down');
    }
    //icon.classList.add('far fa-chevron-down');
  }

  onResize() {
    if (typeof window !== 'undefined' && window.innerWidth < 768) {
      this.setState({
        collapse: this.props.isOpen
      });
    } else {
      this.setState({
        collapse: true
      });
    }
  }

  toggle() {
    const { collapse } = this.state;
    if (typeof window !== 'undefined' && window.innerWidth < 768) {
      console.log('collapse', !collapse);
      this.setState({collapse: !collapse});
    }
  }

  render() {
    const { iconClass, collapse } = this.state;
    const jobDescription = this.props.data;

    return (
      <Container className="jd-section-padding jd-section no-padding" onClick={this.toggle}>
        <Row noGutters>
          <Col md={12} className="jd-heading">
            <h5 className="field-label">{this.props.fieldLabel}</h5>
            <button type="button" className="add-jobs" onClick={this.toggle}>
              <i ref={this.icon} id={`${this.props.fieldLabel.split(' ').join('-')}-icon`} className={`${this.state.collapse ? 'far fa-chevron-up': 'far fa-chevron-down'}`} aria-hidden="true" />
            </button>
          </Col>
          <Col md={12}>
            <Collapse isOpen={collapse}>
              {
                <div className="field-value"
                dangerouslySetInnerHTML={{
                  __html: jobDescription && jobDescription.raw && jobDescription.raw.length > 1 ? jobDescription.html : jobDescription.text,
                }}
                />
              }
            </Collapse>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default JobDescription;

JobDescription.propTypes = {
  jobDescription: PropTypes.object
};