import React from 'react';
import { Button } from "reactstrap";
import { getColor, createUrl } from "../utils/universalHelperFunctions";
import '../styles/careerDescription.css';
import CarouselImages from "./../components/Carousel";

const SliceContainer = (props) => {
  console.log('SliceContainer', props);
  const carousalImages = props.items?.filter(item => {
    return !!item.carousel_image?.url;
  })
  // debugger;
  const value = ((props.primary?.description?.text === null || props.primary?.description?.text === undefined) && (props.primary?.link_text?.text === null || props.primary?.link_text?.text === undefined) && (props.primary?.description !== undefined)) ? "0px" : "44px";

  return <div className="number_banner_container" style={{ backgroundColor: props.primary?.background_colour ? getColor(props.primary?.background_colour) : 'white', paddingBottom: value }} id={props.primary?.id}>
    <div className="container" style={{ color: '#313542' }}>
      <div className="row align-items-center justify-content-center">
        {
          props.primary?.image_position === "Top" && props.primary?.image?.url && <div className={`col-12 ${props.primary?.image_columns} align-items-center justify-content-center mb-4`}>
            <img src={props.primary.image?.url}></img>
          </div>
        }
        {/* <div className={`col-12 ${props.primary.image_columns} align-items-center justify-content-center mb-4`}>
          {
            props.primary.image_position === "Top" && props.primary.image?.url && <img src={props.primary.image?.url}></img>
          }
        </div> */}
        {props.primary?.title?.text && (
          <div className="col-12 mb-4">
            <div className="number-banner-title">
              <span dangerouslySetInnerHTML={{ __html: props.primary?.title?.text }}></span>
              {/* <span>{" "}</span>
                  <span style={{ fontWeight: "bold" }}>{` ${title[1]}`}</span> */}
            </div>
          </div>
        )}
        {props.primary?.testimonial_heading?.text && (
          <div className="col-12 mb-4">
            <div className="number-banner-title">
              <span dangerouslySetInnerHTML={{ __html: props.primary?.testimonial_heading?.text }}></span>
              {/* <span>{" "}</span>
                  <span style={{ fontWeight: "bold" }}>{` ${title[1]}`}</span> */}
            </div>
          </div>
        )}

        {props.primary?.social_media_header?.text && (
          <div className="col-12 mb-4">
            <div className="number-banner-title">
              <span dangerouslySetInnerHTML={{ __html: props.primary?.social_media_header?.text }}></span>
              {/* <span>{" "}</span>
                  <span style={{ fontWeight: "bold" }}>{` ${title[1]}`}</span> */}
            </div>
          </div>
        )}

        {
          props.primary?.description?.text && <div className="col-12 mb-4 number-banner-description" dangerouslySetInnerHTML={{ __html: props.primary?.description?.text }}>
          </div>
        }
        {
          props.primary?.image_position === "Bottom" && props.primary?.image?.url && <div className={`col-12 col-md-12 align-items-center justify-content-center`}>

            <img src={props.primary?.image?.url} style={{ maxHeight: 'fit-content', minWidth: '100%' }}></img>

          </div>
        }
      </div>
      {
        props.children && <div className={`row align-items-center description ${props.justifyCenter ? 'justify-content-center': ''}`}>
          {props.children}
        </div>
      }
    </div>
    {
      <div className="row align-items-center justify-content-center description">

        {
          carousalImages?.length > 0 ? <div className="col-12 align-items-center justify-content-center pb-3">

            <CarouselImages items={carousalImages} />
          </div> : <></>
        }

        {
          props.primary?.link_text?.text && <div className="col-12 align-items-center justify-content-center" style={{ display: "flex" }}>
            <a
              className="lead d-md-block"
              href={props.primary?.description_link?.url}
              hrefLang="en"
            >
              {props.primary?.link_text?.text && <Button color="primary" className="btn btn-primary" style={{ borderRadius: "0px" }}>{props.primary?.link_text?.text}</Button>}
            </a>
          </div>
        }
      </div>
    }
  </div>

}

export default SliceContainer;
