import React from "react"
import SliceContainer from "../components/SliceContainer";
import { createUrl } from "../utils/universalHelperFunctions";
import "../styles/careerDescription.css"

const GspannValuesSection = (props) => {

    const data = props.input?.primary;
    const items = props.input?.items;
    console.log("GspannValuesSection", items);
    return <SliceContainer {...props.input}>
        {
            items.map((item, index) => {
                return <div key={index} className={`col-md-6 col-lg-4  align-items-center justify-content-center`}>
                    <div className="row align-items-center justify-content-center">
                        <div key={index} className={`col-md-12  align-items-center justify-content-center mb-2`} style={{display:'flex', textAlign: 'center'}}>
                            <img className="image" src={item.repeatable_image.url}></img>
                        </div>
                        <div className={`col-md-12  align-items-center justify-content-center mb-2 our-values-content-container`}>
                            <div key={index} className={`col-md-12  align-items-center justify-content-center mb-2`} style={{display:'flex', textAlign: 'center'}}>
                                <span className="item-heading">{item.repeatable_heading.text}</span>
                            </div>
                            <div key={index} className={`col-md-12  align-items-center justify-content-center`} style={{display:'flex', textAlign: 'center'}}>
                            <span className="item-description">{item.repeatable_description.text}</span>
                            </div>
                        </div>
                    </div>
                </div>
            })
        }
    </SliceContainer>
};

export default GspannValuesSection;