
import React from "react"
import WalkinCard from "../components/WalkinCard";
import SliceContainer from "../components/SliceContainer";

const WalkinCardList = (props) => {
  console.log("WalkinCardList props: ", props)
  let upcomingEventCardsElement = "";
  let pastEventCardsElement = "";
  const cardData = props.input
  let upcomingEventCardsDataItems = [];
  let pastEventCardsDataItems = [];

  cardData.items.forEach((item, key) => {
    let eventStartDate = new Date(item.event_start_date_and_time).getTime();
    let eventEndDate = new Date(item.event_end_date_and_time).getTime();
    if (new Date().getTime() < eventStartDate || (new Date().getTime() > eventStartDate && new Date().getTime() < eventEndDate)) {
      upcomingEventCardsDataItems.push(item);
    } else {
      delete item.card_button_link;
      delete item.card_button_text;

      pastEventCardsDataItems.push(item);
    }
  });
  if (upcomingEventCardsDataItems) {
    upcomingEventCardsDataItems && upcomingEventCardsDataItems.sort((item1, item2) => {
      return new Date(item2.event_start_date_and_time) - new Date(item1.event_start_date_and_time) && new Date(item2.event_end_date_and_time) - new Date(item1.event_end_date_and_time);
    });
  }
  if (pastEventCardsDataItems) {
    pastEventCardsDataItems && pastEventCardsDataItems.sort((item1, item2) => {
      return new Date(item2.event_start_date_and_time) - new Date(item1.event_start_date_and_time) && new Date(item2.event_end_date_and_time) - new Date(item1.event_end_date_and_time);
    });
  }
  console.log('pastEventCardsDataItems:', pastEventCardsDataItems);

  if (upcomingEventCardsDataItems && upcomingEventCardsDataItems.length > 0) {
    upcomingEventCardsElement = upcomingEventCardsDataItems.map(upcommingEventCard => {
      return <WalkinCard cardData={upcommingEventCard} pagesLinksData={props.pagesLinksData}  backgroundColor={'#FFFFFF'}/>
    });
  } else {
    upcomingEventCardsElement = (
      <div className="container">
        <p className="no-card-msg"
          dangerouslySetInnerHTML={{
            __html:
              cardData.primary.no_upcoming_card_message.html || cardData.primary.no_upcoming_card_message.raw[0].text,
          }}
        />
      </div>
    )
  }
  if (pastEventCardsDataItems && pastEventCardsDataItems.length > 0) {
    pastEventCardsElement = pastEventCardsDataItems.map(pastEventCard => {
      return <WalkinCard cardData={pastEventCard} pagesLinksData={props.pagesLinksData}  backgroundColor={'#F6F5F1'}/>
    });
      
    
  } else {
    pastEventCardsElement = (
      <div className="container">
        <p className="no-card-msg"
          dangerouslySetInnerHTML={{
            __html:
            cardData.primary.no_past_card_message.html || cardData.primary.no_past_card_message.raw[0].text,
          }}
        />
      </div>
    )
  } 
  const upcommitSectionTitle = cardData.primary.card_section_upcoming_event_subtitle?.text?.split(" ");
  console.log('upcommitSectionTitle:', upcommitSectionTitle);
  const pastSectionTitle = cardData.primary.card_section_past_event_subtitle?.text?.split(" ")
  const upcommingdata = {title: {text: `<b>${upcommitSectionTitle[0]}</b> ${upcommitSectionTitle[1]}`}, background_colour: '#F6F5F1'};
  const pastData= {title: {text: `<b>${pastSectionTitle[0]}</b> ${pastSectionTitle[1]}`}};
  return <div>
    <SliceContainer primary = {upcommingdata}>
      {upcomingEventCardsElement}
    </SliceContainer>
    <SliceContainer primary = {pastData}>
      {pastEventCardsElement}
    </SliceContainer>
  </div>
}

export default WalkinCardList;