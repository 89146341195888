import React, { Component } from "react";
import Carousel, { Dots } from '@brainhubeu/react-carousel';
import SliceContainer from "../components/SliceContainer";
import '@brainhubeu/react-carousel/lib/style.css';
import '../styles/testimonial-component.css';
import PropTypes from "prop-types";

class TestiminialComponentSlice extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: 0,
    }
    this.onchange = this.onchange.bind(this);
    console.log("xdfghjhgfdsxdfgh in testimonial", this.props);
  }


  onchange(value) {
    this.setState({ value });
  }

  render() {
    const title = this.props.input.primary.testimonial_heading.text;
    const sliceSlides = [];
    this.props.input.items.forEach((element, key) => {

      let slideItem = (
        <div className="testimonial-container row">
          <div className="testimonial-image col-03">
            <img src={element.testimonial_image.url} />
          </div>
          <div className="testimonial-content col-09">
            <div className="testimonial-content_sub">
              <h5 style={{ marginBottom: "0px" }}>{element.employee_name.text}</h5>
              <p>{element.employee_designation.text}</p>
              <p>{element.testimonial_content.text}</p>
            </div>
          </div>
        </div>)
      sliceSlides.push(slideItem);
    });

    return (
      <SliceContainer justifyCenter={true} {...this.props.input}>
        <>
          <Carousel
            value={this.state.value}
            slides={sliceSlides}
            onChange={this.onchange}
          />
          <Dots value={this.state.value} onChange={this.onchange} number={sliceSlides.length} />
        </>
      </SliceContainer>
    );
  }
}

export default TestiminialComponentSlice;

TestiminialComponentSlice.propTypes = {
  input: PropTypes.object,
};
