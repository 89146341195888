import React, { useState, useEffect } from "react";
import {
    Container
} from "reactstrap";
import BackgroundImage from 'gatsby-background-image';
import { getColor, createUrl, checkCorporateEmail } from "../utils/universalHelperFunctions";
import AnimatedText from "../components/AnimatedText";
import "../styles/career-banner.css";

const CareerBanner = (props) => {
    const [sliderHeight, setSliderHeight] = useState();
    const { input } = props;
    const { primary, items } = input;

    useEffect(() => {
        //window.addEventListener('resize', (e) => { this.onResize();});
    }, []);
    console.log("banner items", items);
    const carouselTextClass = window.location.pathname === '/jobs-for-freshers/' || '/jobs-for-experienced/';
    const sources = [];
    if (primary.banner_image?.MobileHDPI && primary.banner_image?.MobileHDPI?.localFile) {
        sources.push({
            ...primary.banner_image?.MobileHDPI?.localFile?.childImageSharp?.fluid,
            media: `(max-width: 767px)`
        })
    }
    if (primary.banner_image.Tablet && primary.banner_image.Tablet.localFile) {
        sources.push({
            ...primary.banner_image?.Tablet?.localFile?.childImageSharp?.fluid,
            media: `(min-width: 768px) and (max-width: 1023px)`,
        });
    }
    if (primary.banner_image?.Desktop && primary.banner_image?.Desktop?.localFile) {
        sources.push({
            ...primary.banner_image?.Desktop?.localFile?.childImageSharp?.fluid,
            media: `(min-width: 1024px)`,
        });
    }

    return (
        <React.Fragment key={primary.primary_heading.text}>
            <BackgroundImage
                Tag="div"
                fluid={sources}
                className={`sliderImageHeight banner-background-image`}
                style={{ backgroundImage: `url(${primary.banner_image.url})`, backgroundSize: 'cover' }}
            >
                {/* <div className={`${props.hero_image_overlay == "true" ? 'image-overlay' : ''}`} > */}
                <div className={`career-carousel-media-overlay ${window.location.pathname === "/lets-co-create/" || window.location.pathname === "/walkin-events/" ? 'justify-content-start' : ''}`} style={props.isOverlay ? { backgroundColor: 'black', opacity: 0.7 } : {}}>
                    {/* <div className="carousel-label-wrapper">
                                    <h6 className="carousel-label">{primary.primary_heading.text}</h6>
                                </div> */}
                    <div className="row align-items-center" style={{ display: (window.location.pathname === "/life-at-gspann" || window.location.pathname === "/life-at-gspann/") ? "block" : "flex" }} >
                        <div className={`${(window.location.pathname === "/lets-co-create" || window.location.pathname === "/lets-co-create/") ? "col-md-6 primary-text" : "col-md-9"} no-padding`}>
                            <div className={`carousel-heading ${(window.location.pathname === "/lets-co-create" || window.location.pathname === "/lets-co-create/") ? '' : 'jobs-heading'}`} style={(window.location.pathname === "/lets-co-create" || window.location.pathname === "/lets-co-create/") ? {} : { paddingLeft: "0px", paddingBottom: '0rem', justifyContent: "flex-start", color: 'white' }} dangerouslySetInnerHTML={{ __html: primary.primary_heading.text }}></div>
                        </div>
                        {
                            items[0].animated_text?.text != null ? <div className="col-md-5 no-padding secondary-text ani_txt">
                                <div className={`animated-text px-2`}>
                                    <AnimatedText items={items} />
                                </div>
                            </div> : <></>
                        }
                    </div>
                    <div className="row justify-content-center align-items-center" style={{ display: "block" }} >

                        <div className="col-md-12 no-padding  secondary-text">
                            <div className={`${carouselTextClass ? 'carousel-text-large' : 'carousel-text'}`} dangerouslySetInnerHTML={{ __html: primary.secondary_heading.text }}></div>
                        </div>
                    </div>
                    <div className="row justify-content-center align-items-center" style={{ display: "block" }} >
                        <div className="col-md-12 no-padding">
                            <div className={`banner-description`} dangerouslySetInnerHTML={{ __html: primary.banner_description.text }}></div>
                        </div>
                    </div>

                </div>
                {/* </div> */}
            </BackgroundImage>
        </React.Fragment >
    )
}
export default CareerBanner;
