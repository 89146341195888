import React, { useState, useEffect } from "react";
import ReactTextTransition, { presets } from "react-text-transition";
import { getRandomInt } from "../utils/universalHelperFunctions";
import "../styles/animatedText.css"
import { FaDeviantart } from "react-icons/fa";

const AnimatedText = (props) => {
    console.log('animated:', props.items);

    const [textIndex, setTextIndex] = useState(0);

    useEffect(() => {
        let interval = setInterval(() => {
            setTextIndex(getRandomInt(0, props.items.length-1));
        }, 2000);

        return () => {
            clearInterval(interval);
        };
    }, []);
    return <div className="inline">
        <ReactTextTransition
            springConfig={presets.gentle}
            className="big"
            delay={300}
            inline
        >
            {props.items[textIndex]?.animated_text?.text}
        </ReactTextTransition>
    </div>
    /* {
            props.items.map(item => <div>{item.animated_text?.text}</div>)
        } */

}

export default AnimatedText;