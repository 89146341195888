const getColor = (elementColor) => {
    if (elementColor.indexOf('~') > -1) {
      elementColor = elementColor.slice(elementColor.indexOf('~') + 1).trim();
      elementColor = elementColor.slice(elementColor.indexOf('[') + 1, elementColor.indexOf(']'));
    }
    return elementColor;
};
const replaceCharAt = (str, index, replacement) => {
    return str.substr(0, index) + replacement + str.substr(index + replacement.length);
};
const createUrl = (url, linkData) => {
    if (url && linkData) {
        let pgId = url.split('/')[1];
        let pgIndex = linkData.findIndex(function (nodes) {
        return nodes.node.uid === pgId;
        })
        if (pgIndex !== -1) {
        let pgDetails = linkData[pgIndex].node.data;
        let finalUrl;
        if (pgDetails.page_display_name) {
            finalUrl = `${pgDetails.page_path.text ? '/' + pgDetails.page_path.text : ''}/${pgDetails.page_display_name.text.split(" ").join("-")}/`
        }
        else {
            finalUrl = `${pgDetails.page_path.text ? '/' + pgDetails.page_path.text : ''}/${pgDetails.resource_display_name.text.split(" ").join("-")}/`
        }
          return finalUrl.toLowerCase();
    }
        else {
            if(url==='resources/blogs/streaming-analytics:-data-processing-options-on-google-cloud-platform'){
              url = "resources/blogs/streaming-analytics-data-processing-options-on-google-cloud-platform"
            }
        
        return `/${url}/`;
        }
    }
};
const isParamPresentInURL = (name, url) => {
    var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(url);
    return results ? true : false;
};
const getFormattedDate = (dateVal) => {
    let startDate = new Date(dateVal);
    let month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(startDate);
    let year = startDate.getFullYear();
    let date = startDate.getDate();
    let formattedDate = `${month} ${date}, ${year}`;

    return formattedDate;
};
const camelize = (str) => {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index == 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
};

const checkCorporateEmail = async (emailID) => {
    let siteUrls = `https://apilayer.net/api/check?access_key=${process.env.EMAIL_VALIDATE_API_KEY}&email=${emailID}&catch_all=1`;
    let response = await fetch(siteUrls, {
    crossDomain: true,
    method: "GET",
    })
    .then(response => response.json())
    .then(response => {
        let domainName = emailID.substring(emailID.lastIndexOf("@") +1);
        if(domainName === "gspann.com") {
            if (response.format_valid && !response.free && !response.disposable &&
                response.mx_found && !response.role && response.score > 0.32) {
                return { valid: true };
            } else {
                return { valid: false };
            }
        }else {
            if (response.format_valid && !response.free && !response.disposable &&
                response.mx_found && response.smtp_check && !response.role && response.catch_all && response.score > 0.32) {
                return { valid: true };
            } else {
                return { valid: false };
            }
        }
    });

    return response;
}

const mapCounterNumbers = (stats = []) => {
    return (stats || []).map(({ number_value, number_key }) => {
        const value = (number_value || {}).text || "";
        const label = (number_key || {}).text || "";
        const suffix = (value || "").indexOf("+") !== -1 ? "+" : ""
        return {
            value: parseInt(value),
            label,
            suffix
        }
    })
}

function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

function getDateContent(startDateInput, endDateInput) {
    let startDateContent = new Date(startDateInput);
    let startMonth = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(startDateContent);
    let startYear = startDateContent.getFullYear();
    let startDate = startDateContent.getDate();
    let startWeekDay = new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(startDateContent);

    let endDateContent = new Date(endDateInput);
    let endMonth = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(endDateContent);
    let endYear = endDateContent.getFullYear();
    let endDate = endDateContent.getDate();
    let endWeekDay = new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(endDateContent);

    if (startYear === endYear && startMonth === endMonth && startDate === endDate && startWeekDay === endWeekDay) {
      return `${startWeekDay}, ${startMonth} ${startDate}, ${startYear}`;
    } else if (startYear === endYear) {
      return `${startMonth} ${startDate} - ${endMonth} ${endDate}, ${endYear}`;
    } else {
      return `${startMonth} ${startDate}, ${startYear} - ${endMonth} ${endDate}, ${endYear}`;
    }
  }

function getTimeContent(startDateInput, endDateInput) {
    startDateInput = new Date(startDateInput);
    endDateInput = new Date(endDateInput);

    let options = {
      hour: 'numeric', minute: 'numeric',
    };
    startDateInput = new Intl.DateTimeFormat('en-US', options).format(startDateInput);
    endDateInput = new Intl.DateTimeFormat('en-US', options).format(endDateInput);
    return `${startDateInput} - ${endDateInput}`;
  }


export {
    getColor,
    replaceCharAt,
    createUrl,
    isParamPresentInURL,
    getFormattedDate,
    camelize,
    checkCorporateEmail,
    mapCounterNumbers,
    getRandomInt,
    getDateContent,
    getTimeContent
}