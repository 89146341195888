import React, { Component } from "react";
import "../styles/call-to-action-strip.css";
import "../styles/inline-form-display-section.css";
import {
  AvForm,
  AvField,
  AvInput,
} from "availity-reactstrap-validation";
import axios from "axios";
import {
  Col,
  Row,
  Button,
  Label,
  Modal,
  ModalBody,
  ModalHeader
} from "reactstrap";
import { PropTypes } from "prop-types";
import { getColor, createUrl, isParamPresentInURL, camelize, getFormattedDate, checkCorporateEmail } from "../utils/universalHelperFunctions";

class InlineFormDisplaySection extends Component {
  constructor(props) {
    super(props);
    this.linksData = props.pagesLinksData;
    this.overlayFormBannerData = props.input;
    this.pageOverViewData = this.props.pageOverViewData;
    this.pageHeaderBodyData = this.props.pageHeaderBodyData;
    this.pageHeroData = this.props.pageHeroData;
    this.formCallPage = this.overlayFormBannerData.primary.form_call_page;

    if (this.overlayFormBannerData.primary.hasOwnProperty("node_api_url") && this.overlayFormBannerData.primary.node_api_url.text) {
      this.formAPIUrl = this.overlayFormBannerData.primary.node_api_url.text;
    } else {
      this.formAPIUrl = "#";
    }
    this.overlayTimer = undefined;
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleInvalidSubmit = this.handleInvalidSubmit.bind(this);
    this.hideFormSection = this.hideFormSection.bind(this);
    this.submitFormData = this.submitFormData.bind(this);
    this.validateEmailField = this.validateEmailField.bind(this);
    this.createEmailDataObject = this.createEmailDataObject.bind(this);
    this.state = {
      showBannerSection: true,
      showOverlayForm: true,
      showThanks: false,
      showSpinner: false,
      serverError: '',
      emailValidationError: '',
      formId: '',
      alreadySignedIn: false,
      isSingleRowBanner: this.overlayFormBannerData.primary.banner_view === "Single Row" || false,
      isBtnAttachedToInput: this.overlayFormBannerData.primary.is_button_attach_to_form_field ? this.overlayFormBannerData.primary.is_button_attach_to_form_field === "true" ? true : false : this.overlayFormBannerData.primary.banner_view === "Single Row" ? true: false,
      selectedFile: null,
      selectedFileName: null,
      hideSubmit: false,
      thankYouMessage: '',
      submitBtnClass: '',
      fromActiveCampaign: false,
      showDownloadForm: false,
    };
  }

  toggleDownloadForm = () => {
    if(this.showDownloadForm) {
      this.setState(({showDownloadForm}) => ({showDownloadForm: !showDownloadForm}));
    } else if(this.props.pageId === 'aiops-in-monitoring11') {
      const { input: { primary: { file_content: { url } } } } = this.props;
      var a = document.createElement("a");
      a.href = url;
      a.target = '_blank';
      let fileName = url.split("/").pop();
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    } else {
      this.setState(({showDownloadForm}) => ({showDownloadForm: !showDownloadForm}));
    }
  }

  componentDidMount() {
    let url = window.location.href || '';
    let pathName = window.location.pathname ? window.location.pathname.replace(/\//g, ' ') : '';
    pathName = pathName.trim();
    pathName = pathName.split(' ');
    if (pathName.length && pathName[0]) {
      this.setState({ formId: `${pathName[0].toLocaleLowerCase()}` });
    }
    if (this.formCallPage === "Case Study" && isParamPresentInURL('id', url)) {
      this.setState({ showOverlayForm: false, fromActiveCampaign: false });
      let urlParams = new URLSearchParams(window.location.search);
      let value = urlParams.get('id');
      checkCorporateEmail(value)
      .then(response => {
        if (response.valid) {
          this.setState({
            showOverlayForm: false,
            fromActiveCampaign: true
          });
        } else {
          this.setState({
            showOverlayForm: true,
            fromActiveCampaign: false
          })
        }
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.thankYouMessage !== prevState.thankYouMessage && this.state.thankYouMessage !== "") {
      this.timeoutID = setTimeout(() => {
        this.setState({ thankYouMessage: "", hideSubmit: false, submitBtnClass:"" });
        this.setState({ showOverlayForm: true });
        this.setState({ isCorporateEmail: false });
        if (this.props.handleAction) {
          this.props.handleAction(false);
        }
      }, 50000);
    }
  }

  hideFormSection = () => {
    document.getElementsByTagName('html')[0].style.removeProperty('overflow');
    if (this.state.isSingleRowBanner) {
      clearTimeout(this.overlayTimer);
      this.setState({ showThanks: false, showBannerSection: true, alreadySignedIn: false, showOverlayForm: true });
    }
    this.setState({ emailValidationError: '' });
    this.setState({ showSpinner: false, hideSubmit: false });
    this.refs.form.reset();
  };
  hideOverlayFormSection = () => {
    this.refs.form.reset();
    this.setState({ showOverlayForm: false, emailValidationError: '' });
  };
  showThanksSection = () => {
    this.setState({ showThanks: true, showBannerSection: false });
    setTimeout(() => {
      this.hideThanksSection();
    }, 10000);
  };
  showOverlayThanksSection() {
    this.setState({ showThanks: true, showBannerSection: false });
    this.overlayTimer = setTimeout(() => {
      this.hideOverlayThanksSection();
      this.hideFormSection();
    }, 10000);
  }
  hideOverlayThanksSection = () => {
    this.setState({ showThanks: false, showBannerSection: true, alreadySignedIn: false, showOverlayForm: true, hideSubmit: true });
  };
  hideThanksSection = () => {
    this.setState({ showThanks: false, showBannerSection: true });
  };

  generateKeyForEmailData(element) {
    let pos = element.indexOf("_");
    while (pos > -1) {
      element = element.slice(0, pos + 1) + element.charAt(pos + 1).toUpperCase() + element.slice(pos + 2);
      pos = element.indexOf('_', pos + 1);
    }
    element = element.replace(new RegExp('_', 'g'), '');

    return element;
  }

  createEmailDataObject(values, formDataObj) {
    let emailData = [];
    let workEmail = values.workEmail || values.companyEmailAddress;

    if(this.props.emailTemplateData && this.props.emailTemplateData.length > 0) {
      if(this.overlayFormBannerData.primary.form_identifier) {
        this.props.emailTemplateData.forEach(template =>  {
          if(this.overlayFormBannerData.primary.form_identifier && template.primary.form_identifier_for_teamplate === this.overlayFormBannerData.primary.form_identifier) {
            this.emailTemplate =  template.items;
          }
        });
      } else {
        this.emailTemplate = this.props.emailTemplateData[0].items;
      }
    }

    if(this.state.domainName !== "gspann.com" && this.overlayFormBannerData.primary.document_form_link && this.overlayFormBannerData.primary.document_form_link.url) {
      let allSlices = this.overlayFormBannerData.primary.document_form_link.document[0].data.body;
      const IndexForOverlayForm = obj =>
      obj.__typename === "PrismicResourceBodyOverlayFormDisplaySection";
      let overlayIndex = allSlices.findIndex(IndexForOverlayForm);
      let emailTemplateData = allSlices.filter(slice => ['PrismicResourceBodyEmailTemplateLinks', 'PrismicPagePageContentEmailTemplateLinks', 'PrismicHomepagePageContentEmailTemplateLinks',"PrismicPopupModalBodyEmailTemplateLinks"].includes(slice.__typename));
      if(emailTemplateData && emailTemplateData.length > 0) {
        var flag = false;
        emailTemplateData.forEach(template =>  {
          if(template.primary.form_identifier_for_teamplate && template.primary.form_identifier_for_teamplate === allSlices[overlayIndex].primary.form_identifier) {
            flag = true;
            //this.emailTemplate[this.emailTemplate.findIndex(element => element.user_type === 'Sales')] =  template.items.find(element => element.user_type === 'Sales');
            this.emailTemplate =  template.items;
          }
        });
        if(!flag) {
          this.emailTemplate = emailTemplateData[0].items;
        }
      }
    }     
    if(this.state.domainName === "gspann.com" && this.overlayFormBannerData.primary.document_form_link && this.overlayFormBannerData.primary.document_form_link.url) {
      let allSlices = this.overlayFormBannerData.primary.document_form_link.document[0].data.body;
      const IndexForOverlayForm = obj =>
      obj.__typename === "PrismicResourceBodyOverlayFormDisplaySection";
      let overlayIndex = allSlices.findIndex(IndexForOverlayForm);
      let emailTemplateData = allSlices.filter(slice => ['PrismicResourceBodyEmailTemplateLinks', 'PrismicPagePageContentEmailTemplateLinks', 'PrismicHomepagePageContentEmailTemplateLinks'].includes(slice.__typename));

      if(emailTemplateData && emailTemplateData.length > 0) {
        var flag = false;
        emailTemplateData.forEach(template =>  {
          if(template.primary.form_identifier_for_teamplate && template.primary.form_identifier_for_teamplate === allSlices[overlayIndex].primary.form_identifier) {
            flag = true;
            this.emailTemplate =  template.items;
          }
        });
        if(!flag) {
          this.emailTemplate = emailTemplateData[0].items;
        }
      }
    }

    if (this.emailTemplate) {
      this.emailTemplate.forEach(element => {
        if (this.props.resourceType && this.props.resourceType === 'Case Study' && (element.user_type === "User" || element.user_type === "User with WP")) {
          if(this.state.domainName === "gspann.com") {
            // if(!(this.overlayFormBannerData.primary.document_form_link && this.overlayFormBannerData.primary.document_form_link.url)) {
            //   return;
            // }
            if(element.user_type === "User with WP" ) {
              return;
            }
          } else {
            if((this.overlayFormBannerData.primary.document_form_link && this.overlayFormBannerData.primary.document_form_link.url)) {
              if(formDataObj.signUpForEmails) {
                if(element.user_type === "User" ) {
                  return;
                }
              } else {
                return;
              }
            }
          }
        } else if(this.props.resourceType && this.props.resourceType === 'White Paper' && (element.user_type === "User" || element.user_type === "User with WP")) {
          if(this.state.domainName === "gspann.com") {
            if(!(this.overlayFormBannerData.primary.file_content && this.overlayFormBannerData.primary.file_content.url)) {
              return;
            }
            if(element.user_type === "User with WP" ) {
              return;
            }
          } else {
            if((this.overlayFormBannerData.primary.file_content && this.overlayFormBannerData.primary.file_content.url)) {
              if(formDataObj.signUpForEmails) {
                if(element.user_type === "User" ) {
                  return;
                }
              } else {
                return;
              }
            }
          }
        }
        
        if (element.email_template_link && element.email_template_link.document.length > 0) {
          let emailObj = {};
          let emailTemp = element.email_template_link && element.email_template_link.document[0].data;
          let signature = emailTemp.signature && emailTemp.signature.raw.length > 1 ? emailTemp.signature.html : emailTemp.signature.raw[0].text;
          let content = emailTemp.email_content ? emailTemp.email_content.html ? emailTemp.email_content.html : emailTemp.email_content.raw[0].text : '';
          let salutation = emailTemp.salutation ? emailTemp.salutation.html ? emailTemp.salutation.html : emailTemp.salutation.raw[0].text : '';

          // let regX = new RegExp(/href=(["'])(.*?)\1/, 'gi');
          // if(content.match(regX) !== null) {
          //   if(content.match(regX).length > 0) {
          //     content.match(regX).forEach(url => {
          //       let link = url;
          //       link = link.replaceAll('"', '');
          //       link = link.split("=");
          //       link[1] = `${createUrl(link[1], this.linksData)}`;
          //       link = link.join('=');
          //       content = content.replace(url, link);
          //     });
          //   }
          // }

          if(new RegExp('href=\"https:\/\/{pageUrl}\"', 'g').exec(content)) {
            content = content.replace(/href="https:\/\/{pageUrl}"/g, `href=${values.PageUrl}`);
          }

          if (values) {
            for (let key in values) {
              let expression = '\{' + key + '\}';
              let regEx = new RegExp(expression, 'gi');
              if (content.match(regEx) != null) {
                content = content.replace(regEx, values[key]);
              }
              if (salutation && salutation.match(regEx) != null) {
                salutation = salutation.replace(regEx, values[key]);
              }
              if (signature && signature.match(regEx) != null) {
                signature = signature.replace(regEx, values[key]);
              }
            }
          }

          if (this.resourceOverviewData) {
            for (let element in this.resourceOverviewData.primary) {
              let key = this.generateKeyForEmailData(element);
              let expression = '\{' + key + '\}';
              let regEx = new RegExp(expression, 'gi');
              if (content.match(regEx) != null) {
                content = content.replace(regEx, this.resourceOverviewData.primary[element].text);
              }
              if (salutation && salutation.match(regEx) != null) {
                salutation = salutation.replace(regEx, this.resourceOverviewData.primary[element].text);
              }
              if (signature && signature.match(regEx) != null) {
                signature = signature.replace(regEx, this.resourceOverviewData.primary[element].text);
              }
            }
          }

          if (this.pageOverViewData) {
            for (let element in this.pageOverViewData.primary) {
              let key = this.generateKeyForEmailData(element);

              let expression = '\{' + key + '\}';
              let regEx = new RegExp(expression, 'gi');
              if (content.match(regEx) != null) {
                content = content.replace(regEx, this.pageOverViewData.primary[element].text);
              }
              if (salutation && salutation.match(regEx) != null) {
                salutation = salutation.replace(regEx, this.pageOverViewData.primary[element].text);
              }
              if (signature.match(regEx) != null) {
                signature = signature.replace(regEx, this.pageOverViewData.primary[element].text);
              }
            }
          }

          if (this.pageHeroData) {
            this.pageHeroData.items.forEach(item => {
              for (let element in item) {
                let key = this.generateKeyForEmailData(element);
                let expression = '\{' + key + '\}';
                let regEx = new RegExp(expression, 'gi');
                if (content.match(regEx) != null) {
                  content = content.replace(regEx, item[element].text);
                }
                if (salutation && salutation.match(regEx) != null) {
                  salutation = salutation.replace(regEx, item[element].text);
                }
                if (signature.match(regEx) != null) {
                  signature = signature.replace(regEx, item[element].text);
                }
              }
            });
          }

          if (this.pageHeaderBodyData) {
            for (let element in this.pageHeaderBodyData.primary) {
              let key = this.generateKeyForEmailData(element);

              let expression = '\{' + key + '\}';
              let regEx = new RegExp(expression, 'gi');
              if (content.match(regEx) != null) {
                content = content.replace(regEx, this.pageHeaderBodyData.primary[element].text);
              }
              if (salutation && salutation.match(regEx) != null) {
                salutation = salutation.replace(regEx, this.pageHeaderBodyData.primary[element].text);
              }
              if (signature.match(regEx) != null) {
                signature = signature.replace(regEx, this.pageHeaderBodyData.primary[element].text);
              }
            }
          }

          if (element.description && element.description.html) {
            let descriptionList = element.description.html.split(new RegExp('<p>\\s*@+\\*+@+\\s*<\/p>', 'g'));
            if (descriptionList) {
              let descriptionObj = {}
              descriptionList.forEach(element => {
                let key = element.match(new RegExp('<p>.*(::)\s*<\/p>'));
                if (key) {
                  key = key[0].replace(new RegExp('\s*<\/?[^>]+(>|$)\s*', 'g'), '').replace('::', '').trim();
                  descriptionObj[key] = element.split(new RegExp('<p>.*(::)\s*<\/p>'))[2];
                } else if (element.match(new RegExp('<p>.*(::).*<\/p>'))) {
                  key = element.match(new RegExp('<p>.*(::).*<\/p>'));
                  key = key[0].replace(new RegExp('\s*<\/?[^>]+(>|$)\s*', 'g'), '').split('::');
                  if (key) {
                    descriptionObj[key[0]] = key[1];
                  }
                } else {
                  return;
                }
              });
              if (descriptionObj && Object.entries(descriptionObj).length > 0) {
                for (let key in descriptionObj) {
                  let expression = '\{' + key + '\}';
                  let regEx = new RegExp(expression, 'gi');
                  if (content.match(regEx) != null) {
                    content = content.replace(regEx, descriptionObj[key]);
                  }
                  if (salutation && salutation.match(regEx) != null) {
                    salutation = salutation.replace(regEx, descriptionObj[key]);
                  }
                  if (signature.match(regEx) != null) {
                    signature = signature.replace(regEx, descriptionObj[key]);
                  }
                }
              }
            }
          }

          if (this.formCallPage === "Events") {
            if (this.resourceOverviewData) {
              let startDate = getFormattedDate(this.resourceOverviewData.primary.event_start_timestamp);
              let endDate = getFormattedDate(this.resourceOverviewData.primary.event_end_timestamp);

              content = content.replace('{eventDate}', `${startDate} - ${endDate}`);
            }
          }

          if (this.props.resourceType && (this.props.resourceType === "White Paper" || this.props.resourceType === 'Case Study')) {
            let id = `${workEmail}`;

            if(this.overlayFormBannerData.primary && this.overlayFormBannerData.primary.file_content && this.overlayFormBannerData.primary.file_content.url) {
              let downloadUrl = this.overlayFormBannerData.primary.file_content.url;
              if(downloadUrl) {
                if (new RegExp('href=\"https:\/\/{url}\"', 'g').exec(content)) {
                  content = content.replace(/href="https:\/\/{url}"/g, `href=${downloadUrl}`)
                }
                content = content.replace(/{url}/gi, downloadUrl);
              }
            } else {
              let downloadUrl = this.state.fromActiveCampaign ? `${window.location.href}?id=${id}`: `${window.location.href}`;
              if (new RegExp('href=\"https:\/\/{url}\"', 'g').exec(content)) {
                content = content.replace(/href="https:\/\/{url}"/g, `href=${downloadUrl}`)
              }
  
              content = content.replace(/{url}/gi, downloadUrl);
            }

            if (!(element.user_type === "User" || element.user_type === "User with WP")) {
              if(values.rootURL) {
                content = content.concat(`<p>Landing Page: <a href =${values.rootURL}> ${formDataObj.pageDisplayName} </a></p>`);
              }
              if(content.indexOf('Signed up for email:') < 0) {
                content = content.concat(`<p>Signed up for email: ${formDataObj.signUpForEmails ? 'Yes' : 'No'}</p>`);
              }
            }
          }

          if (emailTemp.subject.text) {
            emailObj.sub = emailTemp.subject.text;
          }

          if (this.state.domainName && this.state.domainName === "gspann.com") {
            let regExForRecipient = new RegExp('\{gspann-.*\}', 'gi');
            if (emailTemp.recipient && emailTemp.recipient.text && emailTemp.recipient.text.match(regExForRecipient)) {
              let matchArray = emailTemp.recipient.text.match(regExForRecipient);
              if (matchArray && matchArray.length > 0) {
                emailTemp.recipient.text = matchArray.join(',');
              }
            }
            if (emailTemp.cc_recipient && emailTemp.cc_recipient.text && emailTemp.cc_recipient.text.match(regExForRecipient) != null) {
              let matchArray = emailTemp.cc_recipient.text.match(regExForRecipient);
              if (matchArray && matchArray.length > 0) {
                emailTemp.cc_recipient.text = matchArray.join(',');
              }
            }
            if (emailTemp.bcc_recipient && emailTemp.bcc_recipient.text && emailTemp.bcc_recipient.text.match(regExForRecipient) != null) {
              let matchArray = emailTemp.bcc_recipient.text.match(regExForRecipient);
              if (matchArray && matchArray.length > 0) {
                emailTemp.bcc_recipient.text = matchArray.join(',');
              }
            }
            if (emailTemp.from_recipient && emailTemp.from_recipient.text && emailTemp.from_recipient.text.match(regExForRecipient) != null) {
              let matchArray = emailTemp.from_recipient.text.match(regExForRecipient);
              if (matchArray && matchArray.length > 0) {
                emailTemp.from_recipient.text = matchArray.join(',');
              }
            }
          }
          if (emailTemp.body && emailTemp.body.length > 0) {
            emailTemp.body.forEach(element => {
              if (element.slice_type === 'recipient_list' && element.items && element.items.length) {
                element.items.forEach(element => {
                  let expression = `\{${element.team_name.text}\}`;
                  let regEx = new RegExp(expression, 'gi');

                  if (emailTemp.recipient && emailTemp.recipient.text && emailTemp.recipient.text.match(regEx) != null) {
                    emailTemp.recipient.text = emailTemp.recipient.text.replace(regEx, element.recipients.text);
                  }
                  if (emailTemp.cc_recipient && emailTemp.cc_recipient.text && emailTemp.cc_recipient.text.match(regEx) != null) {
                    emailTemp.cc_recipient.text = emailTemp.cc_recipient.text.replace(regEx, element.recipients.text);
                  }
                  if (emailTemp.bcc_recipient && emailTemp.bcc_recipient.text && emailTemp.bcc_recipient.text.match(regEx) != null) {
                    emailTemp.bcc_recipient.text = emailTemp.bcc_recipient.text.replace(regEx, element.recipients.text);
                  }
                  if (emailTemp.from_recipient && emailTemp.from_recipient.text && emailTemp.from_recipient.text.match(regEx) != null) {
                    emailTemp.from_recipient.text = emailTemp.from_recipient.text.replace(regEx, element.recipients.text);
                  }
                });
              } else if (element.slice_type === 'default_key_value' && element.items && element.items.length) {
                element.items.forEach(element => {
                  if (element.key.text) {
                    let expression = '\{' + element.key.text + '\}';
                    let regEx = new RegExp(expression, 'gi');
                    let value = element.default_value.text || element.multiline_default_value.html;
                    if (value) {
                      if (content.match(regEx) != null) {
                        content = content.replace(regEx, value);
                      }
                      if (salutation && salutation.match(regEx) != null) {
                        salutation = salutation.replace(regEx, value);
                      }
                      if (signature.match(regEx) != null) {
                        signature = signature.replace(regEx, value);
                      }
                    }

                  }
                });
              }
            });
          }
          if (process.env.GATSBY_ACTIVE_ENV && process.env.GATSBY_ACTIVE_ENV === "staging") {
            if (element.user_type === "User" || element.user_type === "User with WP") {
              emailObj.to = values.workEmail || values.emailAddress;
            } else{
              if(values.workEmail && this.state.domainName === "gspann.com") {
                emailObj.to = values.workEmail;
              } else if(values.emailAddress && values.emailAddress.split('@')[1] === 'gspann.com') {
                emailObj.to = values.emailAddress;
              } else {
                emailObj.to =emailTemp.recipient && emailTemp.recipient.text ? emailTemp.recipient.text : '';
              }
            }
          } else {
            if (element.user_type === "User" || element.user_type === "User with WP") {
              if (this.formCallPage === "Case Study" && this.state.domainName === "gspann.com") {
                emailObj.to = workEmail || values.emailAddress;
              } else {
                emailObj.to = emailTemp.recipient && emailTemp.recipient.text ? emailTemp.recipient.text : workEmail || values.emailAddress;
              }
            } else {
              emailObj.to = emailTemp.recipient && emailTemp.recipient.text ? emailTemp.recipient.text : '';
            }
          }
          salutation = !salutation ? "Hello Visitor" : salutation;

          if (emailTemp.cc_recipient && emailTemp.cc_recipient.text) {
            emailObj.cc = emailTemp.cc_recipient.text;
          }

          if (emailTemp.bcc_recipient && emailTemp.bcc_recipient.text) {
            emailObj.bcc = emailTemp.bcc_recipient.text;
          }

          if (emailTemp.from_recipient && emailTemp.from_recipient.text) {
            emailObj.from = emailTemp.from_recipient.text;
          }

          if (emailTemp.subject.text) {
            emailObj.sub = emailTemp.subject.text;
          }

          let adobeString="Become a Personalization Expert in less than Thirty Pages";
if(content.includes(adobeString)){
  content = content.replace("white paper by GSPANN","ebook by Adobe");
}
          emailObj.emailTempStr = salutation.concat(content, signature);
          emailObj.userType = element.user_type;

          emailData[emailData.length] = emailObj;
        }
      });
    }

    return emailData;
  }

  submitFormData(values) {
    let requestObject = new FormData();
    if (this.formAPIUrl.indexOf(process.env.GATSBY_API_URL) < 0) {
      this.formAPIUrl = this.formAPIUrl.indexOf('/') === 0 ? `${process.env.GATSBY_API_URL}${this.formAPIUrl}` : `${process.env.GATSBY_API_URL}/${this.formAPIUrl}`;
    }

    this.setState({
      data: values
    });
    let successMsg = this.overlayFormBannerData.primary.success_message.text;
    if (this.state.domainName === "gspann.com" && this.formCallPage === "Case Study") {
      if (this.overlayFormBannerData.primary.hasOwnProperty('success_message_for_gspann_user') && this.overlayFormBannerData.primary.success_message_for_gspann_user.text) {

        successMsg = this.overlayFormBannerData.primary.success_message_for_gspann_user.text;
        successMsg = successMsg.replace(/mailbox/g, 'inbox');
      } else {
        successMsg = 'Thank you for requesting the document. Please check your inbox for the document download link.';
      }
    }
    if(this.state.domainName !== "gspann.com" && this.formCallPage === "Case Study" && this.overlayFormBannerData.primary.file_content &&  this.overlayFormBannerData.primary.file_content.url) {
      successMsg = 'Thank you for your request. Our team will share the document after reviewing your request.'
    }
    if(this.formCallPage === "Case Study" && !(this.overlayFormBannerData.primary.file_content &&  this.overlayFormBannerData.primary.file_content.url)) {
      successMsg = 'We appreciate you taking your time to subscribe with us. Stay tuned for some of our latest documents.';
    }
    let errorMsg = this.overlayFormBannerData.primary.failure_message.text;

    let pathName = window.location.pathname ? window.location.pathname.replace(/\//g, ' ') : '';
    pathName = pathName.trim();
    pathName = pathName.split(' ');

    if (pathName.length > 2) {
      requestObject.append('categoryName', pathName[0].toUpperCase());
      requestObject.append('subCategoryName', pathName[1].toUpperCase());
    } else if (pathName.length == 2) {
      requestObject.append('categoryName', pathName[0].toUpperCase());
    } else if (pathName.length < 2) {
      requestObject.append('categoryName', this.props.pageId.toUpperCase());
    }
    requestObject.append('sourceId', this.props.pageId);

    let formObj = {};
    formObj.event = this.overlayFormBannerData.primary.event.text || 'test';
    formObj.eventData = this.overlayFormBannerData.primary.event_data.text || 'test';
    values.pageDisplayName = this.props.pageDisplayName;

    for (let key in values) {
      if (["firstName", "lastName", "workEmail", "emailAddress", "companyEmailAddress"].indexOf(key) > -1) {
        if (['companyEmailAddress', 'workEmail', 'emailAddress'].indexOf(key) > -1) {
          requestObject.append('email', values[key]);
        } else {
          requestObject.append(key, values[key]);
        }
      } else {
        if (key === 'checkbox') {
          formObj.signUpForEmails = values.checkbox;
        } else {
          formObj[key] = values[key];
        }
      }
    }
    //when case study don't have whitepaper to showcase in a form.
    if(this.formCallPage === "Case Study" && !this.state.isSingleRowBanner &&!(this.overlayFormBannerData.primary.document_form_link && this.overlayFormBannerData.primary.document_form_link.url)) {
      formObj.signUpForEmails = true;
    }
    //condition for tag manager triggers setup
    if(this.formCallPage === "Case Study" && !this.state.isSingleRowBanner) {
      if(formObj.signUpForEmails) {
        this.setState({submitBtnClass: 'cs-submit-with-signup'});
      } else {
        this.setState({submitBtnClass: 'cs-submit-without-signup'});
      }
    }
    //condition for tag manager triggers setup
    if(this.formCallPage === "Case Study" && this.state.isSingleRowBanner) {
      this.setState({submitBtnClass: 'cs-submit-for-similar-projects'});
    }
    if(pathName[1].toUpperCase() === "CASE-STUDIES" && this.overlayFormBannerData.primary.document_form_link && this.overlayFormBannerData.primary.document_form_link.url && createUrl(this.overlayFormBannerData.primary.document_form_link.url, this.linksData) !== window.location.pathname) {
      values.PageUrl = `${window.location.origin}${createUrl(this.overlayFormBannerData.primary.document_form_link.url, this.linksData)}`;
      values.rootURL = window.location.href;
      values.pageDisplayName = this.overlayFormBannerData.primary.document_form_link.document[0].data.resource_display_name.text;
    }
    try {
      if (this.props.emailTemplateData && this.props.emailTemplateData.length > 0) {
        requestObject.append('emailData', JSON.stringify(this.createEmailDataObject(values, formObj)));
      }
    } catch (error) {
      console.log(error);
    }

    requestObject.append('formData', JSON.stringify([formObj]));

    let config = {};

    if (this.state.selectedFile) {
      config = {
        headers: {
          'content-type': 'multipart/form-data',
          'Cache-Control': 'no-cache'
        },
      }
    } else {
      config = {
        headers: {
          'Cache-Control': 'no-cache'
        },
      }
    }

    axios.post(this.formAPIUrl, requestObject, config)
    .then(res => {
      if (this.overlayFormBannerData.primary.banner_view === 'Single Row') {
        if (res.data.alreadySignedIn) {
          this.setState({ alreadySignedIn: true });
          successMsg = this.overlayFormBannerData.primary.duplicate_user_success_message.text;
        }
        this.setState({ selectedFile: null });
        this.setState({ showSpinner: false });
        if (!(this.formCallPage === "Case Study")) {
          this.setState({ showOverlayForm: false });
        }
        this.setState({ thankYouMessage: successMsg });
        this.hideOverlayFormSection();
        this.showOverlayThanksSection();
      } else {
        this.setState({ thankYouMessage: successMsg });
        this.hideOverlayFormSection();
        this.showOverlayThanksSection();
      }
    })
    .catch(err => {
      console.log(err.response);
      this.setState({ selectedFile: null });
      if (err.response == undefined) {
        this.hideFormSection();
        this.showThanksSection();
      } else {
        this.setState({ showSpinner: false, hideSubmit: true, serverError: errorMsg });
        if (!(this.formCallPage === "Case Study")) {
          this.setState({ showOverlayForm: false });
        }
        setTimeout(() => {
          this.setState({
            serverError: "",
            hideSubmit: false
          });
          this.refs.form.reset();
        }, 2000);
      }
    });
  }

  handleValidSubmit(event, values) {
    this.setState({ showSpinner: true, hideSubmit: true });
    Object.entries(values).forEach(([key, value]) => {
      if (!(value)) {
        delete values[key];
      }
    });
    values.pageTitle = this.pageOverViewData.primary.page_title.text;
    if (values.workEmail) {
      checkCorporateEmail(values.workEmail)
      .then(response => {
        if ( response.valid ) {
          this.setState({
            isCorporateEmail: true,
            data: values,
            domainName: values.workEmail.substring(values.workEmail.lastIndexOf("@") +1)
          });
          values.PageUrl = window.location.href;
          this.submitFormData(values);
        } else {
          this.setState({ showSpinner: false, hideSubmit: false, emailValidationError: 'Please enter a valid corporate email.' });
        }
      })
      .catch(err => {
        this.setState({ showSpinner: false, hideSubmit: true, emailValidationError: err });
        setTimeout(() => {
          this.setState({
            emailValidationError: "",
            hideSubmit: false
          });
          this.refs.form.reset();
        }, 500);
      });
    } else {
      this.setState({
        data: values
      });
      values.PageUrl = window.location.href;
      this.submitFormData(values);
    }
  }

  handleInvalidSubmit(event, errors, values) {
    console.log(errors, values);
  }

  validateEmailField(event) {
    if (this.state.emailValidationError !== '') {
      this.setState({ emailValidationError: '' });
    }
  }

  fileChangedHandler = event => {
    const inputFile = event.target.files[0];

    this.setState({ selectedFile: inputFile });
    this.setState({ selectedFileName: inputFile.name });
  };

  createFormField(fieldLabel, fieldType, isFieldRequired) {
    let emailValidationError;
    let isRequired = isFieldRequired === "false" ? false : true;
    if (this.state.emailValidationError) {
      emailValidationError = (
        <div className="invalid-feedback">{this.state.emailValidationError}</div>
      )
    }

    if (fieldLabel != null) {
      var keyForRequestData = camelize(fieldLabel);
    }

    switch (fieldType) {
      case "check box":
        return (this.formField = (
          <Col key={fieldLabel}>
            <Label check>
              <AvInput type="checkbox" name={keyForRequestData} required={isRequired} />
              {fieldLabel}
            </Label>
          </Col>
        ));

      case "Multiline":
        return (this.formField = (
          <Col key={fieldLabel}>
            <AvField
              type="textarea"
              name={keyForRequestData}
              id="messageText"
              placeholder={`Message${isRequired ? '*' : ''}`}
              bsSize="lg"
              className="message-input"
              validate={{
                required: { value: isRequired, errorMessage: `Please enter message.` },
              }}
            />
          </Col>
        ));

      case "Text Box":
        if (keyForRequestData === "location(City)") {
          keyForRequestData = "currentLocation";
        }

        return (this.formField = (
          <Col key={fieldLabel} className={`no-padding ${this.formCallPage === "Case Study" ? 'center-align-field' : ''} ${this.state.emailValidationError ? "email-input-row" : ''} no-padding`}>
            <AvField
              type={fieldType}
              name={keyForRequestData}
              id="location"
              placeholder={`${fieldLabel}${isRequired ? '*' : ''}`}
              className="all-form-input"
              validate={{
                required: { value: isRequired, errorMessage: `Please enter ${fieldLabel}.` },
                pattern: { value: "^[A-Z a-z]*$", errorMessage: `Only letters are allowed in the ${fieldLabel}.` },
              }}
            />
          </Col>
        ));

      case "Email":
        if (keyForRequestData === "emailAddress") {
          return (this.formField = (
            <Col key={fieldLabel} className={`no-padding`} md={this.overlayFormBannerData.primary.banner_view === 'Single Row' ? 12 : 6}>
              <AvField
                type="email"
                name={keyForRequestData}
                id="emailAddress"
                placeholder={`${fieldLabel}${isRequired ? '*' : ''}`}
                className={`all-form-input ${this.state.errorClass}`}
                validate={{
                  required: { value: isRequired, errorMessage: `Field can’t be left blank. Please enter an email.` },
                  email: { value: true, errorMessage: `This is not a valid email format. Please enter a valid email.` },
                }}
              />
            </Col>
          ));
        } else {
          return (this.formField = (
            <Col key={fieldLabel} className={`no-padding ${this.formCallPage === "Case Study" ? 'center-align-field' : ''} ${this.state.emailValidationError ? "email-input-row" : ''}`}>
              <AvField
                type="email"
                name={keyForRequestData}
                id="workEmail"
                placeholder={`${fieldLabel}${isRequired ? '*' : ''}`}
                className={`all-form-input ${this.state.errorClass} ${this.state.emailValidationError ? 'email-input-field' : ''}`}
                validate={{
                  required: { value: isRequired, errorMessage: 'Field can’t be left blank. Please enter a corporate email.' },
                  email: { value: true, errorMessage: "This is not a valid email format. Please enter a valid corporate email." },
                }}
                onChange={this.validateEmailField}
              />
              {emailValidationError}
            </Col>
          ));
        }

      case "Number":
        return (this.formField = (
          <Col key={fieldLabel} className={`no-padding`}>
            <AvField
              name={keyForRequestData}
              id="number"
              placeholder={`${fieldLabel}${isRequired ? '*' : ''}`}
              className="all-form-input"
              validate={{
                required: { value: isRequired, errorMessage: 'Please enter a Phone Number.' },
                pattern: { value: "^[0-9]*$", errorMessage: 'Only numbers are allowed in this field.' },
                minLength: { value: 10, errorMessage: 'Phone number must contains 10 characters.' },
                maxLength: { value: 15, errorMessage: 'Phone number must contains 15 characters.' }
              }}
            />
          </Col>
        ));
      case "File Upload":
        return (this.formField = (
          <Col key={fieldLabel} className={`no-padding`}>
            <label className="custom-file-upload">
              <input
                type="file"
                onChange={this.fileChangedHandler}
                accept=".pdf,.doc,.docx"
                required={isRequired}
              />
              Upload Resume
            </label>
            {
              this.state.selectedFile && (
                <p>{this.state.selectedFile.name}</p>
              )
            }
          </Col>
        ));

      case "Drop Down":
        let dropDownValues = fieldLabel.split("|");
        let dropDownKey;
        switch (dropDownValues[0]) {
          case "Current Salary (Annual)":
            dropDownKey = "currentSalary";
            break;
          case "Notice Period (Days)":
            dropDownKey = "noticePeriod";
            break;
          case "Experience":
            dropDownKey = "totalItExperience";
            break;
          case "Relevant Experience":
            dropDownKey = "relevantExperience";
            break;
        }

        return (this.formField = (
          <Col key={fieldLabel}>
            <AvField
              type="select"
              style={{ fontSize: "1rem" }}
              name={dropDownKey}
              required={isRequired}
              className="form-check-label no-padding"
            >
              {dropDownValues.map((item, i) => (
                <option key={i}>{item}</option>
              ))}
            </AvField>
          </Col>
        ));
    }
  }

  render() {
    const { showBannerSection, showThanks, showOverlayForm } = this.state;
    // console.log(":: FORM ::", this.props, this.state);
    let url = (typeof window === 'undefined') ? '' : window.location.href;
    let displayMsgContainer = null;
    let overlayMsgContainer = null;

    const bannerProps = this.overlayFormBannerData.primary;
    const bannerBackground = bannerProps.banner_background_color
      ? getColor(bannerProps.banner_background_color)
      : "#f1f5f6ee";
    const bannerFontColor = bannerProps.banner_font_color
      ? getColor(bannerProps.banner_font_color)
      : "#000";
    const bannerButtonBackground = bannerProps.banner_button_background
      ? getColor(bannerProps.banner_button_background)
      : "#f1f5f6ee";
    const bannerButtonFontColor = bannerProps.banner_button_font_color
      ? getColor(bannerProps.banner_button_font_color)
      : "#000";
    const overlayBackground = bannerProps.overlay_background_color
      ? getColor(bannerProps.overlay_background_color)
      : "#3498db";
    const overlayFontColor = bannerProps.overlay_font_color
      ? getColor(bannerProps.overlay_font_color)
      : "#3498db";
    const overlayBtnBackground = bannerProps.overlay_button_color
      ? getColor(bannerProps.overlay_button_color)
      : "#3498db";
    const overlayBtnFontColor = bannerProps.overlay_button_font_color
      ? getColor(bannerProps.overlay_button_font_color)
      : "#3498db";
    const isSingleRowBanner = bannerProps.banner_view === 'Single Row' || false;
    const submitBtnClass = this.state.submitBtnClass + " get-in-touch-tag-manager-btn";

    if (this.state.showThanks && !isSingleRowBanner) {
      displayMsgContainer = (
        <div className='thank-you-message msg-container'>
          <div style={{ margin: "40px 0px 20px" }}>
            <span className="thanku-text" style={{ color: bannerFontColor || "#ffffff" }}>
              {this.state.thankYouMessage}
            </span>
          </div>
        </div>
      );
    }
    if (this.state.showThanks && isSingleRowBanner) {
      overlayMsgContainer = (
        <div className='thank-you-message msg-container'>
          <div style={{ margin: "0px auto" }}>
            <span className="thanku-text" style={{ color: bannerFontColor  || "#ffffff" }}>
              {this.state.thankYouMessage}
            </span>
          </div>
        </div>
      )
    }

    let serverErrorContainer = '';
    if (this.state.serverError) {
      serverErrorContainer = (
        <div className="invalid-server-error">{this.state.serverError}</div>
      );
    }

    let id = "";
    if (bannerProps.hasOwnProperty("breadcrumb_id")) {
      id = bannerProps.breadcrumb_id.text;
    }

    let emailValidationError;
    if (this.state.emailValidationError) {
      emailValidationError = (
        <div className="invalid-feedback">{this.state.emailValidationError}</div>
      )
    }

    let formContainsAllFields = [];
    if (this.overlayFormBannerData.items && this.overlayFormBannerData.items.length > 0) {
      this.overlayFormBannerData.items.forEach(element => {
        formContainsAllFields.push(this.createFormField(element.form_field_label.text, element.form_field_type.text, element.form_field_required));
      });
    }

    let formContainsAllFieldsElement;
    if (formContainsAllFields && formContainsAllFields.length > 0 && formContainsAllFields[0]) {
      formContainsAllFieldsElement = (
        <Row form className={`${this.state.isSingleRowBanner && this.state.isBtnAttachedToInput ? 'col-12 col-md-5' : ''} form-section-wrapper`}> {formContainsAllFields}</Row>
      )
    } else {
      formContainsAllFieldsElement = null;
    }

    let signUpCheckboxElement;
    if (this.overlayFormBannerData.items && this.overlayFormBannerData.items.length > 0) {
      signUpCheckboxElement = this.overlayFormBannerData.items.map(element => {
        if(element.form_field_type.text === "Check box") {
          return (this.formField = (
            <Col key={element.form_field_label.text} className="mx-auto checkbox-section">
              <Label check>
                <AvInput type="checkbox" name="checkbox" defaultChecked={true} required={element.form_field_required === "false" ? false : true} />
                {element.form_field_label.text}
              </Label>
            </Col>
          ));
        }
      });
    }
    
    var thumbnailLink = null;
    if(bannerProps.document_form_link && bannerProps.document_form_link.document) {
      let itemBody = bannerProps.document_form_link.document[0].data.body;
      const indexForThimbnail = obj =>
      obj.__typename === "PrismicResourceBodyPageOverview";
      const indexThumbnail = itemBody.findIndex(indexForThimbnail);
      thumbnailLink = itemBody[indexThumbnail] ? itemBody[indexThumbnail].primary.page_thumbnail.url : null;
    } else if(this.pageOverViewData && this.pageOverViewData.primary.page_thumbnail && this.pageOverViewData.primary.page_thumbnail.url) {
      thumbnailLink = this.pageOverViewData.primary.page_thumbnail.url;
    }

    let activeCampaignBtnElement = null;
    let inlineFormHeading = null;

    if(this.formCallPage === "Case Study" && isParamPresentInURL('id', url) && this.state.fromActiveCampaign) {
      if(bannerProps.file_content && bannerProps.file_content.url) {
        activeCampaignBtnElement = (
          <div className="submit-btn-wrapper">
            <Button
              color="primary"
              className={`single-row-button ${submitBtnClass}`}
              style={{
                backgroundColor: bannerButtonBackground,
                color: bannerButtonFontColor,
                display: "flex",
                verticalAlign: 'middle',
                alignItems: 'center',
                flexShrink: 0,
              }}
            >
              <a href={bannerProps.file_content.url} style={{color: bannerButtonFontColor}} hrefLang="en">
                {bannerProps.campaign_form_button_text.text}
              </a>
            </Button>
          </div>
        )
      }
      if (bannerProps.hasOwnProperty("campaign_form_heading")) {
        inlineFormHeading = bannerProps.campaign_form_heading.text;
      }
    }

    let inlineElementStrusture = null;
    const formElement = <div>
    <AvForm
      ref="form"
      className={`form-container ${showOverlayForm ? "" : "d-none"}`}
      onValidSubmit={this.handleValidSubmit}
      onInvalidSubmit={this.handleInvalidSubmit}
    >
      {formContainsAllFieldsElement}
      <div className={`${this.state.showSpinner ? "overlay-container" : ""} submit-btn-wrapper ${this.state.isBtnAttachedToInput ? '' : 'seperate-btn-and-form'}`}>
      {
          bannerProps.banner_button_link && bannerProps.banner_button_link.url ? (
            <Button
              color="primary"
              
              style={{
                backgroundColor: bannerButtonBackground,
                color: bannerButtonFontColor,
                display: "flex",
                verticalAlign: 'middle',
                alignItems: 'center',
                flexShrink: 0,
              }}
              className="p-0"
            >
              <a
              className={`py-2 px-4 single-row-button single-row-button-link ${submitBtnClass} ${this.state.showSpinner ? 'd-none' : ''}`} 
              href={createUrl(bannerProps.banner_button_link.url, this.linksData)} style={{color: bannerButtonFontColor}} hrefLang="en">
                {bannerProps.banner_buttton_text.text}
              </a>
            </Button>
          ) : (
            <Button 
              color="primary"
              className={`single-row-button ${submitBtnClass} ${this.state.showSpinner ? 'd-none' : ''}`}
              style={{
                backgroundColor: bannerButtonBackground,
                color: bannerButtonFontColor,
                display: "flex",
                verticalAlign: 'middle',
                alignItems: 'center',
                flexShrink: 0,
              }}
            >
              {bannerProps.banner_buttton_text.text}
            </Button>
          )
        }
        <div
          className="detail-page-loading overlay"
          onClick={e => e.preventDefault()}
          style={{ display: this.state.showSpinner ? 'block' : 'none' }}
        >
          <div className="spinner" />
        </div>
      </div>
      <div className="multiline-content-section-checkbox">
        {signUpCheckboxElement}
      </div>
    </AvForm>
    {activeCampaignBtnElement}
    <div className="msg-wrapper">
      {overlayMsgContainer}
    </div>
    {serverErrorContainer}
  </div>;
    if(isSingleRowBanner) {
        inlineElementStrusture = (
          <div className={`singlerow-banner-section col-12 ${!this.state.isBtnAttachedToInput ? 'singleRow-with-seperate-btn' : ''}`}>
            <div className={`section-spacing`}>
              {
                !this.state.fromActiveCampaign && bannerProps.section_title && bannerProps.section_title.text && (
                  <h2 className={`${this.state.isBtnAttachedToInput ? 'heading-for-btn-attached' : 'heading-for-seperate-btn'}`}> {bannerProps.section_title.text}</h2>
                )
              }
              {
                !this.state.fromActiveCampaign && bannerProps.banner_section_body && bannerProps.banner_section_body.text && (
                <h3 className="single-row-content">{bannerProps.banner_section_body.text}</h3>
                )
              }
              {
                !this.state.fromActiveCampaign && bannerProps.banner_section_description && bannerProps.banner_section_description.text && (
                  <p
                    className={`single-row-banner-description ${bannerProps.document_form_link && bannerProps.document_form_link.url ? 'spacing-for-large-content' : ''}`}
                    dangerouslySetInnerHTML={{
                      __html: bannerProps.banner_section_description ? bannerProps.banner_section_description.raw.length > 1 ? bannerProps.banner_section_description.html : bannerProps.banner_section_description.raw[0].text : '',
                    }}
                  />
                )
              }
              {
                this.state.fromActiveCampaign && inlineFormHeading && (
                  <h3 className="single-row-banner-description" style={{fontSize: '18px'}}>{inlineFormHeading}</h3>
                )
              }
            </div>
            {(['aiops-in-monitoring11', 'aiops-in-monitoring111']).includes(this.props.pageId) ? <>
              <Button 
                color="primary"
                className={`single-row-button ${submitBtnClass} ${this.state.showSpinner ? 'd-none' : ''}`}
                style={{
                  backgroundColor: bannerButtonBackground,
                  color: bannerButtonFontColor,
                  display: "flex",
                  verticalAlign: 'middle',
                  alignItems: 'center',
                  flexShrink: 0,
                }}
                onClick={this.toggleDownloadForm}
              >
                {/* {bannerProps.banner_buttton_text.text} */}
                Download 
              </Button>
              <Modal className="singlerow-banner-section" isOpen={this.state.showDownloadForm}>
                <ModalHeader>
                  Download {this.props.pageDisplayName} White Paper
                </ModalHeader>
                <ModalBody>{formElement}</ModalBody>
                <button className="close-btn btn" onClick={this.toggleDownloadForm}>
                  <i className="far fa-times fa-2x"></i>
                </button>
              </Modal>
            </> :
            <div>{formElement}</div>
            }
          </div>
        )
    } else {
      inlineElementStrusture = (
        <div className="multilinerow-banner-section" style={bannerProps.document_form_link && bannerProps.document_form_link.url ? {alignItems: 'center'} : {}}>
          <div className="col-md-3 multiline-thumbnail-wrapper no-padding">
            {
              thumbnailLink && (
                <div
                style={{
                  textDecoration: 'none',
                  backgroundImage: `url(${thumbnailLink})`,
                  backgroundSize: '100% 100%',
                  height: '168px',
                  width: '220px',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat'
                }}
              />
              )
            }
          </div>
          <div className="col-md-9 multiline-content-section">
            <h2 className={`multiline-banner-heading`} style={bannerProps.document_form_link && bannerProps.document_form_link.url ? {marginBottom: '0rem', fontSize: '25px', lineHeight: '30px'} : {}}> {bannerProps.section_title.text}</h2>
            <p
              className={`mulitine-banner-description ${bannerProps.document_form_link && bannerProps.document_form_link.url ? 'spacing-for-large-content' : ''}`}
              dangerouslySetInnerHTML={{
                __html: bannerProps.banner_section_description ? bannerProps.banner_section_description.raw.length > 1 ? bannerProps.banner_section_description.html : bannerProps.banner_section_description.raw[0].text : '',
              }}
            />
            <div>
              <AvForm
                ref="form"
                className={`form-container col-12 ${showOverlayForm ? "" : "d-none"} no-padding`}
                onValidSubmit={this.handleValidSubmit}
                onInvalidSubmit={this.handleInvalidSubmit}
              >
                {formContainsAllFieldsElement}
                <div className={`${this.state.showSpinner ? "overlay-container" : ""} submit-btn-wrapper ${this.state.isBtnAttachedToInput ? '' : 'seperate-btn-and-form'}`}>
                  <Button color="primary" className={`single-row-button ${submitBtnClass} ${this.state.showSpinner ? 'd-none' : ''}`}
                    style={{
                      backgroundColor: bannerButtonBackground,
                      color: bannerButtonFontColor,
                      display: "flex",
                      verticalAlign: 'middle',
                      alignItems: 'center',
                      flexShrink: 0,
                    }}
                  >
                    {bannerProps.banner_buttton_text.text}
                  </Button>
                  <div
                    className="detail-page-loading overlay"
                    onClick={e => e.preventDefault()}
                    style={{ display: this.state.showSpinner ? 'block' : 'none' }}
                  >
                    <div className="spinner"/>
                  </div>
                </div>
                <div className="multiline-content-section-checkbox">
                  {signUpCheckboxElement}
                </div>
              </AvForm>
              <div className="msg-wrapper">
                {displayMsgContainer}
              </div>
              {serverErrorContainer}
            </div>
          </div>
        </div>
      )
    }
    
    return (
      <div 
        id={id}
        className="inline-form-display-section col-12"
        style={{
            backgroundColor: bannerBackground,
            color: bannerFontColor,
        }}
      >
        <div className={`single-banner-container ${isSingleRowBanner ? '' : 'banner-container'} ${this.state.formId}-container container`}>
            {inlineElementStrusture}
        </div>
      </div>
    );
  }
}
export default InlineFormDisplaySection;

InlineFormDisplaySection.propTypes = {
  input: PropTypes.object.isRequired,
  pagesLinksData: PropTypes.array
};
