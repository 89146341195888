import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../styles/carousel.css"
// import { Image } from "semantic-ui-react";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    paritialVisibilityGutter: 10
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    paritialVisibilityGutter: 40
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 15
  }
};

// Because this is an inframe, so the SSR mode doesn't not do well here.
// It will work on real devices.
const CarouselImages = ({ deviceType , items}) => {
  return (
    <Carousel
      ssr
      partialVisbile
      deviceType={deviceType}
      itemClass="image-item"
      responsive={responsive}
    >
      {items.map(item => {
        return (
          <img
            style={{ width: "459px", height: "314px", backgroundColor: "#D8D8D8" }}
            src={item.carousel_image.url}
          />
        );
      })}
    </Carousel>
  );
};

export default CarouselImages;
