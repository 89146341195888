import React, { Component } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, Collapse, FormGroup, Label, Input, Form } from 'reactstrap';
import "../styles/job-position.css";

class JobFilter extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.filterObj = this.props.input;
    this.state = { collapse: false, filterdObj: [] };
    this.toggleContries = this.toggleContries.bind(this);
    this.closeJobFilter = this.closeJobFilter.bind(this);
  }
  componentDidMount() {
    let flag = false;
    for (var item of this.filterObj.categoryValue) {
      if (this.props.data.map(x => x.value).indexOf(item) >= 0) {
        flag = true;
        break;
      }
    }
    this.setState({ collapse: flag });
  }
  closeJobFilter() {
    this.setState({ collapse: false });
  }
  toggle() {
    //uncomment this code for auto closing the filter section
    //this.props.closeAllFilterSections();
    this.setState({ collapse: !this.state.collapse });
  }

  // To select and unselet the countries
  toggleContries(item, category, categoryType) {
    if (this.props.data.map(x => x.value).indexOf(item) < 0) {
      this.props.actionAdd(item, category, categoryType)
    } else {
      this.props.actionRemove(item, category)
    }
  }

  render() {
    let data = this.props.data;

    return (
      <Container>
        <Row>
          <Col md={12} className="job-filter">
            <div role="button">
              <div className="position-item" onClick={this.toggle}>
                <h6 className="job-filter-heading">{this.filterObj.categoryName}</h6>
                <button className="add-jobs"><i className={this.state.collapse ? 'fas fa-angle-up filter-arrow-icon' : 'fas fa-angle-down filter-arrow-icon'}></i></button>
              </div>
              <Collapse isOpen={this.state.collapse}>
                <ul className={`filter-list ${this.filterObj.categoryValue && this.filterObj.categoryValue.length > 10 ? 'filter-scroll' : ''}`} >
                  {this.filterObj.categoryValue.map((item, i) => (
                    <li key={i}>
                      <Form>
                      <FormGroup check>
                        <Input type="checkbox" name="check" id={`filterCheckBox${item}`} onChange={() => this.toggleContries(item, this.filterObj.categoryName, this.filterObj.categoryType)} checked={data.map(x => x.value).indexOf(item) >= 0 ? true : false}/>
                        <Label className="checkbox-label" for={`filterCheckBox${item}`} check={data.map(x => x.value).indexOf(item) >= 0 ? true : false}>{item}</Label>
                      </FormGroup>
                      </Form>
                    </li>
                  ))}
                </ul>
              </Collapse>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default JobFilter;
