

import React from 'react'
import { getDateContent, getTimeContent, createUrl } from '../utils/universalHelperFunctions';

const WalkinCard = (props) => {
  console.log("WalkinCard props: ", props)
  const { cardData } = props;
  let walkinDateElement, walkinTimeElement, cardLocationElement, cardButtonElement;
  if (cardData.event_end_date_and_time && cardData.event_start_date_and_time) {
    walkinDateElement = (
      <p>{getDateContent(cardData.event_start_date_and_time, cardData.event_end_date_and_time)}</p>
    )
    walkinTimeElement = (
      <p>{getTimeContent(cardData.event_start_date_and_time, cardData.event_end_date_and_time)}</p>
    );
  } else {
    walkinDateElement = null;
    walkinTimeElement = null;
  }

  if (cardData.event_location && cardData.event_location.text) {
    cardLocationElement = (
      <p>{cardData.event_location.text}</p>
    )
  } else {
    cardLocationElement = null;
  }
  // checking visiblity for card button
  if (cardData.card_button_link?.url) {
    cardButtonElement = (
      <div className="walkin-register-button">
        <a className="walkin-card-button-text" href={createUrl(cardData.card_button_link?.url, props.pagesLinksData)} hrefLang="en">
          {cardData.card_button_text?.text}
        </a>
      </div>
    );
  }
  else {
    cardButtonElement = null;
  }
  return <div className='col-12 col-sm-6 col-lg-4 '><div className='card-design walkin-card-design' style={{ backgroundColor: props.backgroundColor }}>
    <h4 className='card-heading walkin-card-heading'>{cardData.card_heading?.text}</h4>
    <div className="card-desc">
      {walkinDateElement}
      {walkinTimeElement}
      {cardLocationElement}
      
    </div>
    {cardButtonElement}
  </div>
  </div>
}

export default WalkinCard;