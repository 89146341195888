import React, { Component } from "react";
import PropTypes from "prop-types";
import { createUrl, getColor } from "../utils/universalHelperFunctions";
import "../styles/sub-header-mobile.css";

import {
  Nav,
  NavItem,
  NavLink
} from "reactstrap";


class SubHeaderMobile extends Component {
  constructor(props) {
    super(props);
    this.subMenuItems = this.props.subMenuData.items ? this.props.subMenuData.items : this.props.subMenuData;
    this.toggle = this.toggle.bind(this);
    this.linksData = props.pagesLinksData;
    this.state = {
      subMenuItems: [],
      menuDisplay: "inline",
      searchDisplay: "none",
      isOpen: false
    };
  }
  toggle(menu) {
    //this.setState({
    // isOpen: !this.state.isOpen
    //});
    this.setState({ collapsedSubMenu: this.state.collapsedSubMenu === menu ? null : menu });
  }
  displaySubMenu = (menu, e) => {
    if (menu && menu.items) {
      this.setState({ subMenuItems: menu.items });
    }

  };
  displayChildMenus = (childMenu, e) => {
    this.setState({ collapseSubMenu: this.state.collapseSubMenu === childMenu ? null : childMenu });
  };
  hideSubMenu = e => {
    this.setState({ subMenuItems: [] });
  };
  render() {
    return (
      <Nav className="sub-navigation-container ml-auto" navbar >
        {this.subMenuItems.map((subMenu, i) => {
          let subNavLinkLabelFontColor = subMenu.sub_nav_link_label_font_color ? getColor(subMenu.sub_nav_link_label_font_color) : '#fff';
          return subMenu.sub_nav_link_lable.text || subMenu.sub_link_sub_text.text ? (
          <NavItem className="sub-navigation-item" key={i} onMouseEnter={this.displaySubMenu.bind(this, subMenu)} >
            <NavLink
              style={{color: '#fff'}}
              className="sub-navigation-link"
              href={
                subMenu.sub_nav_link && subMenu.sub_nav_link.url
                  ? createUrl(subMenu.sub_nav_link.url, this.linksData)
                  : ""
              }
              hrefLang="en"
            >
              {subMenu.sub_nav_link_lable.text || subMenu.sub_link_sub_text.text}
            </NavLink>

          </NavItem>
        ) : ''}
        )}
      </Nav>
    );
  }
}

export default SubHeaderMobile;

SubHeaderMobile.propTypes = {
  subMenuData: PropTypes.array.isRequired,
  pagesLinksData: PropTypes.array
};
