import React, { useState, useEffect } from "react";
import SliceContainer from "../components/SliceContainer";
import "../styles/profile.css";
import "../styles/image-magnifier-component.css";
import {
    Col,
    Row,
    Button,
    Label,
    Container,
    Modal,
    ModalBody,
    ModalHeader
} from "reactstrap";
// import ProfileDescriptionPopup from './../slices/ProfilePopup';

const DescriptionWithProfiles = (props) => {
    const data = props.input?.primary;
    const items = props.input?.items;
    // const profileImage = items.profile_description_popup_image.url;
    const [ReadStoryImage, setReadStoryImage] = useState("");
    const [showModal, setShowModal] = useState(false);

    const HandleReadStory = (e,profileImage) => {
        setShowModal(true);
        setReadStoryImage(profileImage);
    };

    const toggle = () => {
        console.log("toggle in ProfileDescriptionPopup");
        setShowModal(showModal => !showModal);
    };
    

    console.log("DescriptionWithProfiles", props);
    console.log("openReadStory", ReadStoryImage);

    return <SliceContainer {...props.input}>
        {
            items.map((item, index) => {
                return <div key={index} className={`col-6 col-md-3  align-items-center justify-content-center pt-4`}>
                    <div className="row align-items-center justify-content-center">
                        <div key={index} className={`col-md-12  align-items-center justify-content-center mb-3`} style={{display:'flex'}}>
                            <img className="profile-image" src={item.profile_image.url}></img>
                        </div>
                        <div key={index} className={`col-md-12  align-items-center justify-content-center`} style={{display:'flex'}}>
                            <span className="profile-name">{item.name.text}</span>
                        </div>
                        <div key={index} className={`col-md-12  align-items-center justify-content-center`} style={{display:'flex'}}>
                        <span className="profile-position">{item.designation.text}</span>
                        </div>
                        <div key={index} className={`col-md-12  align-items-center justify-content-center`} style={{display:'flex'}}>
                            <a className="profile-link" onClick={e => HandleReadStory(e, item.profile_description_popup_image.url)}>Read Story</a>
                        </div>
                    </div>
                </div>
            })
        }
        <Container id="ProfileDescriptionPopup">
            <Modal
            className="imageMagnifierModal container"
            isOpen={showModal}
            toggle={toggle}
            style={{top:"17%"}}
            >
            <ModalHeader className="desc_profile_modal_header" toggle={toggle}></ModalHeader>
            <ModalBody className="modal-body-container desc_profile_modal_body">
            <Col className='desc_profile_popup_image'>
                <img alt="About Me" src={ReadStoryImage}></img>
            </Col>
            </ModalBody>
            </Modal>
        </Container>
    </SliceContainer>
}

export default DescriptionWithProfiles;