import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HomePageCardComponent from "./HomePageCardComponent";
import '../styles/home-page-card-section.css';

class WalkinCardsSection extends Component {
  constructor(props) {
    super(props);
    this.linksData = props.pagesLinksData;
    this.CardSectionData = props.input;
    this.state = {
      pastEventCardsData: this.CardSectionData,
      upcomingEventCardsData: this.CardSectionData,
    }
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.CardSectionData.primary.is_walkin_card === "true") {
      let upcomingEventCardsDataItems = [];
      let pastEventCardsDataItems = [];

      this.CardSectionData.items.forEach((item, key) => {
        item.cardType = "walkin-card";
        let eventStartDate = new Date(item.event_start_date_and_time).getTime();
        let eventEndDate = new Date(item.event_end_date_and_time).getTime();
        if (new Date().getTime() < eventStartDate || (new Date().getTime() > eventStartDate && new Date().getTime() < eventEndDate)) {
          upcomingEventCardsDataItems.push(item);
        } else {
          delete item.card_button_link;
          delete item.card_button_text;

          pastEventCardsDataItems.push(item);
        }
      });
      if (upcomingEventCardsDataItems) {
        let upcomingEventCardsData = { ...this.state.upcomingEventCardsData };
        upcomingEventCardsDataItems && upcomingEventCardsDataItems.sort((item1, item2) => {
          return new Date(item2.event_start_date_and_time) - new Date(item1.event_start_date_and_time) && new Date(item2.event_end_date_and_time) - new Date(item1.event_end_date_and_time);
        });
        upcomingEventCardsData.items = upcomingEventCardsDataItems;
        this.setState({ upcomingEventCardsData });
      }
      if (pastEventCardsDataItems) {
        let pastEventCardsData = { ...this.state.pastEventCardsData };
        pastEventCardsDataItems && pastEventCardsDataItems.sort((item1, item2) => {
          return new Date(item2.event_start_date_and_time) - new Date(item1.event_start_date_and_time) && new Date(item2.event_end_date_and_time) - new Date(item1.event_end_date_and_time);
        });
        pastEventCardsData.items = pastEventCardsDataItems;
        this.setState({ pastEventCardsData });
      }
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    // getting card Section Title data from Card Section Data and setting it in cardSectionTitle
    const cardSectionTitle = this.CardSectionData.primary.card_section_title?.text;

    let id = "";
    let upcomingEventCardsElement = "";
    let pastEventCardsElement = "";
    let cardsElement;

    if (this.CardSectionData.primary.hasOwnProperty("breadcrumb_id")) {
      id = this.CardSectionData.primary.breadcrumb_id.text;
    }
    if (this.CardSectionData.primary.is_walkin_card === "true") {
      if (this.state.upcomingEventCardsData && this.state.upcomingEventCardsData.items.length > 0) {
        upcomingEventCardsElement = (
          <HomePageCardComponent cardData={this.state.upcomingEventCardsData} pagesLinksData={this.linksData} />
        );
      } else {
        upcomingEventCardsElement = (
          <div className="container">
            <p className="no-card-msg"
              dangerouslySetInnerHTML={{
                __html:
                  this.CardSectionData.primary.no_upcoming_card_message.html || this.CardSectionData.primary.no_upcoming_card_message.raw[0].text,
              }}
            />
          </div>
        )
      }
      if (this.state.pastEventCardsData && this.state.pastEventCardsData.items.length > 0) {
        pastEventCardsElement = (
          <HomePageCardComponent cardData={this.state.pastEventCardsData} pagesLinksData={this.linksData} />
        );
      } else {
        pastEventCardsElement = (
          <div className="container">
            <p className="no-card-msg"
              dangerouslySetInnerHTML={{
                __html:
                  this.CardSectionData.primary.no_past_card_message.html || this.CardSectionData.primary.no_past_card_message.raw[0].text,
              }}
            />
          </div>
        )
      }
    } else {
      if (this.CardSectionData.items && this.CardSectionData.items.length > 0) {
        cardsElement = (
          <HomePageCardComponent cardData={this.CardSectionData} pagesLinksData={this.linksData} />
        )
      } else {
        cardsElement = null;
      }

    }
    // creating the final Component based on the data
    console.log('this.CardSectionData',this.CardSectionData);
    const upcommitSectionTitle = this.CardSectionData.primary.card_section_upcoming_event_subtitle.text?.split(" ");
  const pastSectionTitle = this.CardSectionData.primary.card_section_past_event_subtitle.text?.split(" ")
  const upcommingdata =  `<b>${upcommitSectionTitle[0]}</b> ${upcommitSectionTitle[1]}`;
  const pastData= `<b>${pastSectionTitle[0]}</b> ${pastSectionTitle[1]}`;
    return (
      <div
        className='walkin-parent-container-card-section'
        id={id}
      >
        <div className="container">
          <h2 style={{ display: cardSectionTitle ? 'visible' : 'none' }}>
            {this.CardSectionData.primary.card_section_title?.text}
          </h2>
        </div>

        {/* sending Cards data to Card Component which renders cards layour accordingly. */}
        {this.CardSectionData.primary.is_walkin_card === "true" ? (
          <div>
            <div className="upcoming-event-section">
              <div className="container">
                <span className="walkin-event-subtitle" dangerouslySetInnerHTML={{__html: upcommingdata}} />
              </div>
              {upcomingEventCardsElement}
            </div>

            {
              this.state.pastEventCardsData && this.state.pastEventCardsData.items.length > 0 && (
                <div className="upcoming-event-section">
                  <div className="container">
                  <span className="walkin-event-subtitle" dangerouslySetInnerHTML={{__html: pastData}} />
                  </div>
                  {pastEventCardsElement}
                </div>
              ) 
            }
          </div>
        ) : cardsElement}
      </div>
    );
  }
}

export default WalkinCardsSection;

WalkinCardsSection.propTypes = {
  input: PropTypes.object,
  pagesLinksData: PropTypes.array
};