import React, { Component } from "react";
import PropTypes from "prop-types";
import SliceContainer from "../components/SliceContainer";
import "./../styles/social-media.css";
import { InstagramEmbed } from 'react-social-media-embed';
import { LinkedInEmbed } from 'react-social-media-embed';
import { TwitterEmbed } from 'react-social-media-embed';

class SocialMedia extends Component {
  constructor(props) {
    super(props)
    this.itemWidth = 318;
    this.itemHeight = 300;
  }

  render() {
    const title = this.props.input.primary.social_media_header.text;
    const mediaCards = [];
    {
      this.props.input?.items.forEach((element, key) => {
        if (element.social_media_type === "LinkedIn") {
          const mediaItem = (
            <div className="social-media-item test">
              <LinkedInEmbed
                url={element.post_url?.url}
                width={this.itemWidth}
                height={this.itemHeight}
              />
            </div>
          );
          mediaCards.push(mediaItem);
        } else if (element.social_media_type === "Instagram") {
          const mediaItem = (
            <div className="social-media-item test" id="social-media-item-instagram">
              <InstagramEmbed
                url={element.post_url?.url}
                width={this.itemWidth}
                height={this.itemHeight}
              />
            </div>
          );
          mediaCards.push(mediaItem);
        } else if (element.social_media_type === "Twitter") {
          const mediaItem = (
            <div className="social-media-item test">
              <TwitterEmbed
                url={element.post_url?.url}
                width={this.itemWidth}
                height={this.itemHeight}
              />
            </div>
          );
          mediaCards.push(mediaItem);
        }
      });
    }


    return (<>
      <SliceContainer  {...this.props.input} >
        <div className="social-media-container" >
          {mediaCards}
        </div>
      </SliceContainer>
      {/* <div className="social-media-header">
    <span dangerouslySetInnerHTML={{ __html: title }}></span>
    </div> */}

    </>
    );
  }
}

export default SocialMedia;

SocialMedia.propTypes = {
  input: PropTypes.object,
};
